import moment from "moment";
import React from "react";
import { useRef } from "react";
import { Translate } from "react-auto-translate";

const DOB = (props) => {

  const handleChange = (event) => {
    if (props?.item?.dataSet?.isRequired) {
      props.item.dataSet.isError = true
      props.item.dataSet.errorMessage = 'Required'
    }

    props.item.value = event.target.value
    props?.validateData()
  }

  const handelBlur = (value) => {
    props.item.value = value
    props?.validateData()
  }

    // // For Date Picker
    // const interviewDateRef = useRef();
    // const handleInterviewDateClick = () => {
    //   const fallbackPicker = document.querySelector('.fallbackDatePicker');
    //   console.log("clicked:--------------------------------------------------------");
    //   fallbackPicker.style.display = 'none';
    //   interviewDateRef.current.focus();
    // };
  

    return (
        <div className={`single-group-child ${props?.['formType']} dob ${props?.completeQuestionData?.isVisible == false ? 'hide-question' : null} ${props?.className} ${props?.item?.dataSet['isError'] ? 'error-in-question' : ''}`}>
        <strong className={`strongclass ${props?.['formType']}`}><Translate>{props?.item?.dataSet?.question}</Translate>{props?.item?.dataSet?.isRequired ? <span className='mandatory'>*</span> : null}</strong>
        <div className={`ui form ${props?.['formType']}`}>
          <div className='field'>
            <input type={'date'} className='textcomponent'
              name={props?.item?.groupName} 
              // value={props.item.value}
              // onClick={handleInterviewDateClick}
              // ref={interviewDateRef}
              style={{height: props?.['formType'] === "soulful-application" ? '53px' : '40px'}}
              defaultValue={props.item.value}
              max={moment().format('YYYY-MM-DD')}
              placeholder={props?.item.placeholder}
              disabled={props?.item?.dataSet?.formDataKey !== "email" ? props?.btnDisabled : false}
              onChange={(event) => {handleChange(event);}}
              onBlur={(event) => handelBlur(event.target.value)}
              >
              </input>
          </div>
          <div>
              {/* <p>
                {props?.item?.notifyMe && props?.item?.dataSet?.formDataKey !== "email" ? 
                  <input className='mx-1' type={'checkbox'} checked={props?.item?.dataSet?.formDataKey === "email" ? props?.item?.emailCommunication : props?.item?.smsCommunication} value="" onChange={(event) => handleCommunicationDetails(event.target.checked)}></input>
                  : null
                }
                {props?.item?.notifyMe ? props?.item?.dataSet?.formDataKey === "email" ? "This is the email you will use to register and log on in the future" : 
                props?.item?.dataSet?.formDataKey === "phoneNumber" ?
                "Send me SMS notifications about training, certifications, career services, job opportunities." : '' : ''
                }
                </p> */}
          </div>
       </div> 
    </div>
    )
}

export default DOB