import React from 'react'
import { Translate } from 'react-auto-translate'
import '../Radio.css'

const TwosideRadio = (props) => {
  
  const handleChange = (event) => {
    props.completeQuestionData['value'] = event.target.value
    props?.validateData()
  }

  const getQuestion = () => {
    if ((props?.item?.dataSet?.question).includes('winter')) {
        const splittedQuestion = (props?.item?.dataSet?.question).split('winter')
        return <p>{splittedQuestion[0]} <span style={{color: 'black'}}><u><Translate>winter</Translate></u></span> <Translate>{splittedQuestion[1]}</Translate> {props?.item?.dataSet?.isRequired ? <span className='required'>*</span> : null}</p>
    } else if ((props?.item?.dataSet?.question).includes('summer')) {
        const splittedQuestion = (props?.item?.dataSet?.question).split('summer')
        return <p>{splittedQuestion[0]} <span style={{color: 'black'}}><u><Translate>summer</Translate></u></span> <Translate>{splittedQuestion[1]}</Translate> {props?.item?.dataSet?.isRequired ? <span className='required'>*</span> : null}</p>    
    } else {
        return props?.item?.dataSet?.question
    }
  }

  return (
    <div className={`single-group-child ${props?.item?.dataSet['isError'] ? 'error-in-question' : ''}`}>
            <strong className='question-element'><Translate>{getQuestion()}</Translate>{props?.item?.dataSet.isRequired ? <span className='required'>*</span> : null}</strong>
            <div className='radiobuttons1'>
              <div className='radioheadings'>
                  <span className='radio-span'></span>
                  {
                      (props?.item?.dataSet?.options).map((option,i)=>{
                          return(
                              <span className='radio-span'>{option}</span>
                          )
                      })
                  }
              </div>
              <div className='question-option'>
                <span className='radio-span'><Translate>{props?.item?.dataSet?.choices[0]}</Translate></span>
                  {
                      Object.keys(props?.item?.dataSet?.options).map((option)=>{
                        return(
                            <div className='radio-span'>
                                <input type="radio" className='form-radio' value={props.item.dataSet['options'][option]} name={props?.item?.dataSet?.groupName}
                                onChange={(event)=>handleChange(event)}
                                checked={props?.item?.value === props?.item?.dataSet?.options[option]}
                                disabled={props?.btnDisabled}
                                ></input>
                            </div>
                        )
                      })
                }
                <span className='radio-span'><Translate>{props?.item?.dataSet?.choices[1]}</Translate></span>
                </div>
            </div>
            {props?.item?.dataSet['isError'] ? <p className='error-message-container mt-2'><Translate>{props?.item?.dataSet['errorMessage']}</Translate></p> : null}
  
        </div>
  )
}

export default TwosideRadio
