import React from 'react'
import { ErrorMessage } from 'formik';
import '../Inputs.css'
import { EMAIL_REGEX } from '../utils/constants';
import { checkUserEmail, checkUserExists, checkWildanRegistrationExists, getStudentApplicationByEmail } from '../Apis/studentApplication';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Translate } from 'react-auto-translate';
import { useRef } from 'react';
import moment from 'moment';

const Input = (props) => {
  // console.log(props)

  const [allowToUserDetails, setAllowToUserDetails] = useState(true)
  const [show, setShow] = useState(false)
  const [userAlreadyFilledFormData, setUserAlreadyFilledFormData] = useState({})

  const handleChange = (event, inputType) => {
    console.log("value length", (''+event.target.value).length, (/^\d+$/).test(event.target.value));
    // console.log(props?.formType)
    if (inputType === "numeric") {
      if ((/^\d+$/).test(event.target.value)) {
        if (props?.item?.value !== undefined && props?.item?.value !== null && (''+event.target.value).length > 0) {
          // If question is email then check email regex
          console.log("(EMAIL_REGEX).test(event.target.value): ", (EMAIL_REGEX).test(event.target.value), event.target.value, props?.item?.value);
          if (props?.item?.dataSet?.formDataKey === "email" || props?.item?.dataSet?.formDataKey === "workEmail") {
            
            if ((EMAIL_REGEX).test(event.target.value)) {
              props.item.dataSet.isError = false
              props.item.dataSet.errorMessage = ''
              let checkingUser = toast.loading(<Translate>Checking if user exists...</Translate>, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
              })
              try {
                if(props.formType === "student" || props.formType === "elevatorPitch" || props.formType === "postNysedra" || props.formType === "preNysedra"){
                  checkUserEmail({email: event.target.value, type: props.formType}).then(res => {
                  console.log("checkUserExists: ", res);
                  if (res.status) {
                    props.item.userVerified = true
                    toast.dismiss(checkingUser)
                    toast.success(<Translate>User Exist</Translate>, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                    })
                    props?.setBtnDisabled(false)
    
                    if(props?.formType === "student" || props?.formType === "elevatorPitch" || props?.formType === "preNysedra"){
                        setShow(true)
                        setUserAlreadyFilledFormData(res.data[0])
                        // props?.setFormData({...props?.formData, fName: {...props?.formData['fName'], value: res.data[0]['firstname'] || '', dataSet: {...props?.formData['fName']['dataSet'], isError: res.data[0]['firstname'] ? false: true, errorMessage: res.data[0]['firstname'] ? "" : "Required"}}, lName: {...props?.formData['lName'], value: res.data[0]['lastname'] || '', dataSet: {...props?.formData['lName']['dataSet'], isError: res.data[0]['lastname'] ? false: true, errorMessage: res.data[0]['lastname'] ? "" : "Required"}}})
                        props?.setUserExistsData(res.data[0])
                    }
                    // else{
                    //   setShow(false)
                    // }
                    //// Check User Form Data Alfreafy Exists or Not
    
                    // getStudentApplicationByEmail(event.target.value).then(res => {
                    //   if (res !== null) {
                    //     if (res.data && res.data.length > 0) {
                    //       props?.prefillApplicationForm(res.data[0])
                    //       setShow(true)
                    //       setUserAlreadyFilledFormData(res.data[0])
                    //     }
                    //   }
                    // }).catch(error => {
                    //   console.log("error: ", error);
                    //   toast.error('Something went wrong')
                    // })
                  } else {
                    props.item.userVerified = false
                    toast.dismiss(checkingUser)
                    toast.error(<Translate>{res.message}</Translate>, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                    })
                    setShow(false)
                    setUserAlreadyFilledFormData({})
                    // let formData = props?.formData
                    // Object.keys(formData).forEach((eachQuestion) => {
                    //   if (formData[eachQuestion].type === "simpleCheckbox" || formData[eachQuestion].type === "checkboxWithOther") {
                    //     formData[eachQuestion]['value'] = []
                    //   } else {
                    //     if (formData[eachQuestion].type) {
                    //       formData[eachQuestion]['value'] = ''
                    //     }
                    //   }
                    // })
                    // console.log("formData formData: ", formData);
                    if (props?.formType === "student" || props?.formType === "willdan-registartion-form") {
                      props?.setFormData({...props?.formData, 'emailVerified' : false, 'phoneVerified' : false})
                    }
                    // const formdata = props?.formData
                    // formdata.forEach((item) => {
                    //   if (item.disable !== undefined) {
                    //     item.disable = true
                    //   }
                    // })
                    // props?.setFormData(formdata)
                    props?.setBtnDisabled(false)
                  }
                  })
                } else if (props?.formType === "willdan-registration-form") {
                  checkWildanRegistrationExists({workEmail: event.target.value}).then(res => {
                    console.log("checkUserExists: ", res);
                    if (res.status) {
                      if (res.data.length) {
                        const emailVerified = res?.isEmailVerified || false
                        props?.setFormData({...props?.formData, 'emailVerified' : emailVerified})
                      }
                      props.item.userVerified = true
                      toast.dismiss(checkingUser)
                      toast.success(<Translate>User Exist</Translate>, {
                          position: "bottom-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                      })
                      props?.setBtnDisabled(false)
                    } else {
                      props.item.userVerified = false
                      toast.dismiss(checkingUser)
                      toast.error(<Translate>{res.message}</Translate>, {
                          position: "bottom-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                      })
                      setShow(false)
                      setUserAlreadyFilledFormData({})
                      props?.setFormData({...props?.formData, 'emailVerified' : false})
                      props?.setBtnDisabled(false)
                    }
                    })
                } else {
                  try {
                    checkUserExists(event.target.value).then(res =>{
                      if(res!==null){
                        if(res.status){
                              props.item.studentMoodleId = (res.data.id).toString();
                              console.log(res.data.studentCourses)
                              props.item.name = res.data.fullname;
                              console.log(props.item.studentMoodleId)
                              props?.setBtnDisabled(false)
                              try {
                                    if (res.data.studentCourses) {
                                      props.item.enrolledCoursesByStudent = res.data.studentCourses;
                                    } else {
                                      props.item.enrolledCoursesByStudent = [];
                                  
                                    }
                                } catch (error) {
                                  props.item.enrolledCoursesByStudent = [];
                                }
                                console.log(props.item.enrolledCoursesByStudent)
                              props?.setCoursesList(res.data.studentCourses || [])
                              toast.dismiss(checkingUser)
                              toast.success(<Translate>User Exist</Translate>, {
                                position: "bottom-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                            })
                          }else {
                            toast.dismiss(checkingUser)
                            toast.error(<Translate>User does not exist</Translate>, {
                                position: "bottom-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                            })
                        }
    
                      }
                    
                    })
                  } catch (error) {
                    toast.dismiss(checkingUser)
                    toast.error(<Translate>Something Went Wrong</Translate>, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                    })
                  }
                }
            } catch (error) {
                toast.dismiss(checkingUser)
                toast.error(<Translate>Something Went Wrong</Translate>, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                })
                // props?.setBtnDisabled(true)
              }
            } else {
              props.item.dataSet.isError = true
              props.item.dataSet.errorMessage = 'Invalid Format'
              props.item.userVerified = false
              if (props?.item?.dataSet?.formDataKey === "email" || props?.item?.dataSet?.formDataKey === "workEmail") {
                props?.setBtnDisabled(true)
                setUserAlreadyFilledFormData({})
                setShow(false)
              }
            }
          
          }
        } else {
          if (props?.item?.dataSet?.isRequired) {
            props.item.dataSet.isError = true
            props.item.dataSet.errorMessage = 'Required'
          }
          if (props?.item?.dataSet?.formDataKey === "email" || props?.item?.dataSet?.formDataKey === "workEmail") {
            setUserAlreadyFilledFormData({})
            setShow(false)
            let formData = props?.formData
            Object.keys(formData).forEach((eachQuestion) => {
              if (formData[eachQuestion].type === "simpleCheckbox" || formData[eachQuestion].type === "checkboxWithOther") {
                formData[eachQuestion]['value'] = []
              } else {
                formData[eachQuestion]['value'] = ''
              }
            })
            props?.setFormData(formData)
            props?.setBtnDisabled(true)
          }
          props.item.userVerified = false
        }
        props.item.value = event.target.value
        props?.validateData()
      }
    } else {
      if (props?.formType === "adny" || props?.formType === "adny-satisfaction") {
        props.item.value = event.target.value
        props?.validateData()
      } else {
        if (props?.item?.value !== undefined && props?.item?.value !== null && (''+event.target.value).length > 0) {
          // If question is email then check email regex
          console.log("(EMAIL_REGEX).test(event.target.value): ", (EMAIL_REGEX).test(event.target.value), event.target.value, props?.item?.value);
          if (props?.item?.dataSet?.formDataKey === "email" || props?.item?.dataSet?.formDataKey === "workEmail") {
            
            if ((EMAIL_REGEX).test(event.target.value)) {
              props.item.dataSet.isError = false
              props.item.dataSet.errorMessage = ''
              let checkingUser = toast.loading(<Translate>{props.formType === "weact-form" ? '' : 'Checking if user exists...'}</Translate>, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
              })
              try {
                if(props.formType === "student" || props.formType === "elevatorPitch"){
                  checkUserEmail({email: event.target.value, type: props.formType}).then(res => {
                  console.log("checkUserExists: ", res);
                  if (res.status) {
                    props.item.userVerified = true
                    toast.dismiss(checkingUser)
                    toast.success(<Translate>User Exist</Translate>, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                    })
                    props?.setBtnDisabled(false)
    
                    if(props?.formType === "student" || props?.formType === "elevatorPitch"){
                        setShow(true)
                        setUserAlreadyFilledFormData(res.data[0])
                        props?.setUserExistsData(res.data[0])
                    }
                    // else{
                    //   setShow(false)
                    // }
                    //// Check User Form Data Alfreafy Exists or Not
    
                    // getStudentApplicationByEmail(event.target.value).then(res => {
                    //   if (res !== null) {
                    //     if (res.data && res.data.length > 0) {
                    //       props?.prefillApplicationForm(res.data[0])
                    //       setShow(true)
                    //       setUserAlreadyFilledFormData(res.data[0])
                    //     }
                    //   }
                    // }).catch(error => {
                    //   console.log("error: ", error);
                    //   toast.error('Something went wrong')
                    // })
                  } else {
                    props.item.userVerified = false
                    toast.dismiss(checkingUser)
                    toast.error(<Translate>{res.message}</Translate>, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                    })
                    setShow(false)
                    setUserAlreadyFilledFormData({})
                    // let formData = props?.formData
                    // Object.keys(formData).forEach((eachQuestion) => {
                    //   if (formData[eachQuestion].type === "simpleCheckbox" || formData[eachQuestion].type === "checkboxWithOther") {
                    //     formData[eachQuestion]['value'] = []
                    //   } else {
                    //     if (formData[eachQuestion].type) {
                    //       formData[eachQuestion]['value'] = ''
                    //     }
                    //   }
                    // })
                    // console.log("formData formData: ", formData);
                    if (props?.formType === "student") {
                      props?.setFormData({...props?.formData, 'emailVerified' : false, 'phoneVerified' : false})
                    }
                    // const formdata = props?.formData
                    // formdata.forEach((item) => {
                    //   if (item.disable !== undefined) {
                    //     item.disable = true
                    //   }
                    // })
                    // props?.setFormData(formdata)
                    props?.setBtnDisabled(false)
                  }
                })
                } else if(props.formType === "willdan-registration-form"){
                  checkWildanRegistrationExists({workEmail: event.target.value}).then(res => {
                  console.log("checkUserExists: ", res);
                  if (res.status) {
                    props.item.userVerified = true

                    if (res.data.length) {
                      const emailVerified = res?.isEmailVerified || false
                      props?.setFormData({...props?.formData, 'emailVerified' : emailVerified})
                    }

                    toast.dismiss(checkingUser)
                    toast.success(<Translate>User Exist</Translate>, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                    })
                    props?.setBtnDisabled(false)
                  } else {
                    props.item.userVerified = false
                    toast.dismiss(checkingUser)
                    toast.error(<Translate>{res.message}</Translate>, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                    })
                    setShow(false)
                    setUserAlreadyFilledFormData({})
                    props?.setFormData({...props?.formData, 'emailVerified' : false})
                    // let formData = props?.formData
                    // Object.keys(formData).forEach((eachQuestion) => {
                    //   if (formData[eachQuestion].type === "simpleCheckbox" || formData[eachQuestion].type === "checkboxWithOther") {
                    //     formData[eachQuestion]['value'] = []
                    //   } else {
                    //     if (formData[eachQuestion].type) {
                    //       formData[eachQuestion]['value'] = ''
                    //     }
                    //   }
                    // })
                    // console.log("formData formData: ", formData);
                    // const formdata = props?.formData
                    // formdata.forEach((item) => {
                    //   if (item.disable !== undefined) {
                    //     item.disable = true
                    //   }
                    // })
                    // props?.setFormData(formdata)
                    props?.setBtnDisabled(false)
                  }
                })
                } else {
                  // ["martin.curtet@gmail.com", "sodusany@nyit.edu", "zvlaco@nyit.edu"]
                  toast.dismiss(checkingUser)
                  // if (event.target.value)
                  if (props.formType !== "weact-form" && props?.formType !== "soulful-application") {
                    const noNeedToHitAPIForTheseEmails = ["clayton2gregory@gmail.com", "irlanda.caminero@gmail.com", "jeninethompson1@gmail.com", "ford.keith000@gmail.com", "khamanigregory12@gmail.com", "santacruz@uhab.org", "meran086@gmail.com", "suprasabrina94@gmail.com", "almodovarstephanie452@gmail.com", "tiffany.achirem@gmail.com", "yminasona@gmail.com", "amiregan.dog@gmail.com", "akmark@syr.edu", "foreighnbagmoney@gmail.com", "martinezisaiah72805@gmail.com", "theejasonbrown1990@yahoo.com", "jwhyte0510@gmail.com", "kharitbranch@gmail.com", "michtalavera09@gmail.com",
                    "ushedwards@gmail.com",
                    "t.bracey29@hotmail.com",
                    "reocommgroup@gmail.com",
                    "vonharveyfranklin@gmail.com",
                    "robles.luciano@ymail.com",
                    "emanawejr@gmail.com",
                    "emekapatrick@gmail.com",
                    "donellturner1990@gmail.com",
                    "ftr002@gmail.com",
                    "ductlesscomfortdesigns@gmail.com",
                    "tonybmore55@gmail.com",
                    "dhruv555shah@gmail.com",
                    "eberkmans@gmail.com",
                    "mpetillo@eastcoastpetro.com",
                    "710ctl@gmail.com",
                    "elliottlaw180@gmail.com",
                    "gerry@activezeb.com",
                    "gpm2123@columbia.edu",
                    "joshbucks15@gmail.com",
                    "shalisa.anderson@gmail.com",
                    "akheembilly@gmail.com",
                    "cybertechray@gmail.com",
                    "dev.sumitkr@gmail.com",
                    "walay4reel@gmail.com", "anagy199359@gmail.com", "arian.nova@gmail.com", "btivllc@yahoo.com", "bertf3737@gmail.com", "carlosrincon0280@gmail.com", "chanikaliddie@outlook.com", "weddingbell76@yahoo.com", "Cchanwfg@gmail.com", "cristina@energyedc.com", "TTTconstructioninc@gmail.com", "dwightaewart@gmail.com", "hsimonny11@gmail.com", "ifun98@hotmail.com", "jalrick31@hotmail.com", "jao4565@gmail.com", "dazong1972@gmail.com", "Bchampmusic@yahoo.com", "Kariefcain15@gmail.com", "ld@stratcoproperty.com", "loushuhari@gmail.com", "Marvindm0601@yahoo.com", "monica.dudley02@gmail.com", "rw@stratcoproperty.com", "robertnannery@gmail.com", "Tkchris520@gmail.com", "shalisa.anderson@gmail.com", "shavonnekeith782@gmail.com", "siulungkwan@gmail.com", "Jawulas77@yahoo.com", "xingdang51@yahoo.com"
                  ]
                    // ["martin.curtet@gmail.com", "sodusany@nyit.edu", "zvlaco@nyit.edu", "dev.sumitkr@gmail.com", "swhankat@nyit.edu", "kfassah@nyit.edu", "jquizena@nyit.edu", "dlynch06@nyit.edu", "ylamboni@nyit.edu", "dsheth04@nyit.edu", "asyed627@gmail.com", "fullwood1948@gmail.com", "amiregan.dog@gmail.com", "bedsidemanner10@gmail.com", "andrewgallolee@gmail.com", "angelia.r55@gmail.com", "arielizardo243@gmail.com", "cfrank2@binghamton.edu", "briskh20@gmail.com", "dejajohnson792@gmail.com", "ew28510@gmail.com", "nakoulmagildas@gmail.com", "jairelthomas@gmail.com", "mjrkmail@gmail.com", "theejasonbrown1990@yahoo.com", "danielsjonathan069@gmail.com", "rodzjona@gmail.com", "josephbeckford278@gmail.com", "kgpogm@gmail.com", "koitakha@gmail.com", "krystalr81@gmail.com", "loushuhari@gmail.com", "marshaahill1@gmail.com", "mauriceclark1990@yahoo.com", "torresmeg279@gmail.com", "munozraysabel@gmail.com", "shamelboyd@yahoo.com", "simranjeetkaur809@gmail.com", "tiffany.achirem@gmail.com", "tkmason12@gmail.com"]

                    const noNeedToHitAPIForTheseEmailsData = [
                      {fName: 'Clayton', lName: 'Gregory', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Irlanda', lName: 'Javier', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Jenine', lName: 'Thompson', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Keith', lName: 'Ford', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Khamani', lName: 'Gregory', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Lucia (Lusia)', lName: 'Santacruz', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Md Habib (Mehdi)', lName: 'Al Mehdi', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Sabrina', lName: 'Supra', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Stephanie', lName: 'Almodovar', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Tiffany', lName: 'Achirem', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Yeno', lName: 'Minasona', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Amina', lName: 'Abdullaeva', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Andrew', lName: 'Mark', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Anthony', lName: 'Brannon', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Isaiah', lName: 'Martinez', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Jason', lName: 'Brown', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Jenise', lName: 'Perez-White', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Khari', lName: 'Branch', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {fName: 'Michelle', lName: 'Talavera', course: 'ADNY Cohort #09 - Building Science Principles', dateOfCourse: '09/13/23', trainerName: ''},
                      {
                        fName: 'Ushlab',
                        lName: 'Edwards',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Torrence',
                        lName: 'Bracey',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Raphael Eluemuno',
                        lName: 'Ajalie',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Von',
                        lName: 'Franklin',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Luciano',
                        lName: 'Robles',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Emmanuel',
                        lName: 'Awe',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Emeka',
                        lName: 'Patrick',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Donell',
                        lName: 'Turner',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Glenroy',
                        lName: 'Walker',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Javier',
                        lName: 'Veloz',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Anthony',
                        lName: 'Bazemore',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Dhruv',
                        lName: 'Shah',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Emmanuel',
                        lName: 'Berkmans',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Micahel',
                        lName: 'Petillo',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Clarke',
                        lName: 'Littlejohn',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Elliott',
                        lName: 'Lawrence',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Gerry',
                        lName: 'Clarke',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Garrett',
                        lName: 'Modeste',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Joshua',
                        lName: 'Lewter',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Shalisa',
                        lName: 'Anderson',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Akheem',
                        lName: 'Billy',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Raymond',
                        lName: 'Zorrilla',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      },
                      {
                        fName: 'Sumit',
                        lName: 'Kumar',
                        course: 'ADNY BSP February Course',
                        dateOfCourse: '02/26/2024',
                        trainerName: 'Matt Dean',
                        trainingOrganization: "AEA"
                      }, 
                      {"fName": "Adewale", "lName": "Kuduyo", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Ahmed", "lName": "Nagy ElHendy", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Arian", "lName": "Nova", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Bernie", "lName": "Barrow", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Bert", "lName": "Francois", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Carlos", "lName": "Rincon", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Chanika", "lName": "Liddie", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Cheryl", "lName": "Hill", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Chong", "lName": "Chan", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "cristina", "lName": "pastrana", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Danny", "lName": "Ling", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      // {"fName": "Daniel", "lName": "Gonzalez", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "DWIGHT", "lName": "EWART", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Helen", "lName": "Ricketts-Simon", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Ilir", "lName": "Fundo", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Jalisalauren", "lName": "Rickenbacker", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Janira", "lName": "Ortiz", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      // {"fName": "Janira", "lName": "Ortiz", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Jiazong", "lName": "Zhou", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Joshua", "lName": "Beauchamp", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Karief", "lName": "Cain", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Lekzin", "lName": "Dorji", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Louvinious", "lName": "Shuhari", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Marvin", "lName": "Murillo", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Monica", "lName": "Dudley", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Raymond", "lName": "Wong", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Robert", "lName": "Nannery", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Rong", "lName": "Huang", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Shalisa", "lName": "Anderson", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Shavonne", "lName": "Keith", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Siu-lung", "lName": "Kwan", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Sulemana", "lName": "Jawula", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""},
                      {"fName": "Xing", "lName": "Dang", "course": "DC37 Track #2 Cohort #1 - MFBA Residential Audito", "dateOfCourse": "03/12/24", "trainerName": ""}            
                    ]
                  //   [{fName: "Martin", lName: "Curtet"}, {fName: "Stephen", lName: "Odusanya"}, {fName: "Zachary", lName: "Vlaco"}, {fName: "Sumit", lName: "Kumar"}, {fName: 'Saurabh Dinkar', lName: ' Whankatte'}, {fName: 'Kpalloe', lName: 'Fassah'}, {fName: 'Jenny Paola', lName: 'Quizena Fernandez'}, {fName: 'Dean', lName: 'Lynch'}, {fName: 'Yedouko Debora', lName: 'Lamboni'}, {fName: 'Dhruvi', lName: 'Sheth'},
                  //   {fName: 'Akbar', lName: 'Syed', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Ali', lName: 'Fullwood', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Ami', lName: 'Regan', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Aminah', lName: 'Tolbert', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Andrew', lName: 'PaltooramGallo', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Angelia', lName: 'Ramkisun', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Ariel', lName: 'lizardo', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Celia', lName: 'Frank', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Christian', lName: 'holloway', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Deja', lName: 'Johnson', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Emily', lName: 'White', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Gilda', lName: 'Nakoulma', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Jaire', lName: 'Thomas', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Jaiyaramakrishna', lName: 'Mahendran', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Jason', lName: 'Brown', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Jonathan', lName: 'Daniels', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Jonathan', lName: 'Rodriguez', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Joseph', lName: 'Beckford', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'keith', lName: 'pittman', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Khadija', lName: 'Koita', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Krystal', lName: 'Roper', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Louvinious', lName: 'Shuhari', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Marsha', lName: 'Hill', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Maurice', lName: 'Clark', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Megan', lName: 'Kelly', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Raysabel', lName: 'Munoz', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Shamel', lName: 'Boyd', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Simranjeet', lName: 'Kaur', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Tiffany', lName: 'Achirem', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',},
                  //   {fName: 'Tiffany', lName: 'Malone', course: 'Building Science Principle', dateOfCourse: '7/11/23', trainerName: 'Michael Dean',}
                  // ]



                    console.log("noNeedToHitAPIForTheseEmailsData: ", noNeedToHitAPIForTheseEmailsData, noNeedToHitAPIForTheseEmails.indexOf(event.target.value))
                    const emailValue = noNeedToHitAPIForTheseEmails.indexOf((event.target.value).toLowerCase())
                    if (emailValue !== -1) {
                      props.item.studentMoodleId = "87678"
                      const user = noNeedToHitAPIForTheseEmailsData[emailValue]
                      console.log("user: ", user);
                      props.item.name = `${user.fName} ${user.lName}`
                      props?.setBtnDisabled(false)
                      props?.setCoursesList([{shortname: "NYITC6", fullname: "NYIT Cohort #6 - HVAC Systems and Energy Efficiency Measures"}, {shortname: "AEA", fullname: "ADNY Cohort #09 - Building Science Principles"}, {shortname: "AEA", fullname: "ADNY BSP February Course"}, {shortname: "DC37", fullname: "DC37 Track #2 Cohort #1 - MFBA Residential Audito"}])
                      props.item.enrolledCoursesByStudent = [{shortname: "NYITC6", fullname: "NYIT Cohort #6 - HVAC Systems and Energy Efficiency Measures"}, {shortname: "AEA", fullname: "ADNY Cohort #09 - Building Science Principles"}, {shortname: "AEA", fullname: "ADNY BSP February Course"}, {shortname: "DC37", fullname: "DC37 Track #2 Cohort #1 - MFBA Residential Audito"}];
                      setTimeout(() => {
                        // props?.setFormData({...props?.formData, fName: {...props?.formData['fName'], value: res.data['firstname'], dataSet: {...props?.formData['fName']['dataSet'], isError: "res.data['firstname']" ? false: true, errorMessage: "res.data['firstname']" ? "" : "Required"}}, lName: {...props?.formData['lName'], value: "res.data['lastname']" || '', dataSet: {...props?.formData['lName']['dataSet'], isError: "res.data['lastname']" ? false: true, errorMessage: "res.data['lastname']" ? "" : "Required"}}})
                        if (props?.formType !== "soulful-application") {
                          props?.setFormData({...props?.formData,
                            fName: {...props?.formData['fName'], value: user.fName || '', dataSet: {...props?.formData['fName']['dataSet'], isError: user.fName ? false: true, errorMessage: ""}}, 
                          
                            lName: {...props?.formData['lName'], value: user.lName, dataSet: {...props?.formData['lName']['dataSet'], isError: user.lName ? false: true, errorMessage: ""}},
                          
                            course: {...props?.formData['course'], value: user?.course ? user?.course : "NYIT Cohort #6 - HVAC Systems and Energy Efficiency Measures", 
                            dataSet: {...props?.formData['course']['dataSet'], isError: false, errorMessage: ""}},
                            
                            dateOfCourse: {...props?.formData['dateOfCourse'], value: user?.dateOfCourse ? moment(user?.dateOfCourse).format('YYYY-MM-DD') : "2023-01-23", dataSet: {...props?.formData['dateOfCourse']['dataSet'], isError: false, errorMessage: ""}}, 
                            
                            TrainerName: {...props?.formData['TrainerName'], value: user?.trainerName ? user?.trainerName : user?.trainerName ? user?.trainerName : "", dataSet: {...props?.formData['TrainerName']['dataSet'], isError: user?.trainerName ? false : true, errorMessage: ""}},

                            TrainingOrganization: {...props?.formData['TrainingOrganization'], value: user?.trainingOrganization ? user?.trainingOrganization : user?.trainingOrganization ? user?.trainingOrganization : "", dataSet: {...props?.formData['TrainingOrganization']['dataSet'], isError: user?.trainingOrganization ? false : true, errorMessage: ""}}
                          }
                          )
                        }
                      }, 500)
                      
                      console.log("user 1: ", props?.formData)
                      // props?.prefillApplicationForm({attributes: {lName: user.lName, fName: user.fName}})
                    } else {
                      try {
                        checkUserExists(event.target.value).then(res =>{
                          if(res!==null){
                            if(res.status){
                                  props.item.studentMoodleId = (res.data.id).toString();
                                  console.log(res.data.studentCourses)
                                  props.item.name = res.data.fullname;
                                  console.log(props.item.studentMoodleId)
                                  props?.setBtnDisabled(false)
                                  try {
                                        if (res.data.studentCourses) {
                                          props.item.enrolledCoursesByStudent = res.data.studentCourses;
                                        } else {
                                          props.item.enrolledCoursesByStudent = [];
                                      
                                        }
                                    } catch (error) {
                                      props.item.enrolledCoursesByStudent = [];
                                    }
                                    // console.log(props.item.enrolledCoursesByStudent)
                                    props?.setCoursesList(res.data.studentCourses || [])
  
                                    // Prefill first Name and last name in case of pre survey and post survey form
                                    props?.setFormData({...props?.formData, fName: {...props?.formData['fName'], value: res.data['firstname'] || '', dataSet: {...props?.formData['fName']['dataSet'], isError: res.data['firstname'] ? false: true, errorMessage: res.data['firstname'] ? "" : "Required"}}, lName: {...props?.formData['lName'], value: res.data['lastname'] || '', dataSet: {...props?.formData['lName']['dataSet'], isError: res.data['lastname'] ? false: true, errorMessage: res.data['lastname'] ? "" : "Required"}}})
  
                                  toast.dismiss(checkingUser)
                                  toast.success(<Translate>User Exist</Translate>, {
                                    position: "bottom-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                })
                              }else {
                                toast.dismiss(checkingUser)
                                toast.error(<Translate>User does not exist</Translate>, {
                                    position: "bottom-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                })
                            }
                            
                          }
                        
                        })
                      } catch (error) {
                        toast.dismiss(checkingUser)
                        toast.error(<Translate>Something Went Wrong</Translate>, {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                        })
                      }
                    }
                  } else {
                    props?.setBtnDisabled(false)
                  }
                }
            } catch (error) {
              console.log("error: ", error);
                toast.dismiss(checkingUser)
                toast.error(<Translate>Something Went Wrong</Translate>, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                })
                // props?.setBtnDisabled(true)
              }
            } else {
              props.item.dataSet.isError = true
              props.item.dataSet.errorMessage = 'Invalid Format'
              props.item.userVerified = false
              if (props?.item?.dataSet?.formDataKey === "email" || props?.item?.dataSet?.formDataKey === "workEmail") {
                props?.setBtnDisabled(true)
                setUserAlreadyFilledFormData({})
                setShow(false)
              }
            }
          
          }
        } else {
          if (props?.item?.dataSet?.isRequired) {
            props.item.dataSet.isError = true
            props.item.dataSet.errorMessage = 'Required'
          }
          if (props?.item?.dataSet?.formDataKey === "email" || props?.item?.dataSet?.formDataKey === "workEmail") {
            setUserAlreadyFilledFormData({})
            setShow(false)
            let formData = props?.formData
            Object.keys(formData).forEach((eachQuestion) => {
              if (formData[eachQuestion].type === "simpleCheckbox" || formData[eachQuestion].type === "checkboxWithOther") {
                formData[eachQuestion]['value'] = []
              } else {
                formData[eachQuestion]['value'] = ''
              }
            })
            props?.setFormData(formData)
            props?.setBtnDisabled(true)
          }
          props.item.userVerified = false
        }
        props.item.value = event.target.value
        props?.validateData()
      }
    }
  }

  const handelBlur = (value) => {
    props.item.value = value
    props?.validateData()
  }

  const handleCommunicationDetails = (checkValue) => {
    setAllowToUserDetails(checkValue)
    if (props?.item?.dataSet?.formDataKey === "email" || props?.item?.dataSet?.formDataKey === "workEmail") {
      props.item.emailCommunication = checkValue
    } else {
      props.item.smsCommunication = checkValue
    }
  }

  // Prefill the data
  const handlePrefillForm = () => {
    props?.prefillApplicationForm(userAlreadyFilledFormData)
    setShow(false)
  }

  // Fill Fresh Form
  const handleFillFreshForm = () => {
    if (props?.formType === "student") {
      props?.setFormData({...props?.formData, 'emailVerified' : userAlreadyFilledFormData['attributes']['emailVerified'], 'phoneVerified' : userAlreadyFilledFormData['attributes']['phoneVerified']})
    }

    if (props?.formType === "willdan-registration-form") {
      props?.setFormData({...props?.formData, 'emailVerified' : userAlreadyFilledFormData['attributes']['emailVerified']})
    }

    setShow(false)
  }


  return (
    <div className={`single-group-child ${props?.formType} ${props?.className} ${props?.item?.isVisible !== undefined && !props?.item?.isVisible ? 'hide-question' : null} ${props?.item?.dataSet['isError'] ? 'error-in-question' : ''}`}>
      <strong className={`strongclass ${props?.formType}`}><Translate>{props?.item?.dataSet?.question}</Translate>{props?.item?.dataSet?.isRequired ? <span className='mandatory'>*</span> : null}</strong>
      <div className={`ui form ${props?.formType}`}>
        <div className='field'>
          <input type={props?.item?.inputType} className='textcomponent soulful'
            name={props?.item?.groupName}
            // value={props.item.value}
            defaultValue={props.item.value}
            // placeholder={props?.item.placeholder}
            disabled={props?.item?.dataSet?.question === "Date" ? true : (props?.item?.dataSet?.formDataKey !== "email" || props?.item?.dataSet?.formDataKey === "workEmail") ? props?.btnDisabled : false}
            onChange={(event) => (props?.item?.dataSet?.formDataKey === "email" || props?.item?.dataSet?.formDataKey === "workEmail") ? function(){} : handleChange(event, props?.item?.inputType)}
            onBlur={(event) => (props?.item?.dataSet?.formDataKey === "email" || props?.item?.dataSet?.formDataKey === "workEmail") ? handleChange(event, props?.item?.inputType) : function(){}}
            >
            </input>
        </div>
        <div>
          <p>
            {props?.item?.notifyMe && (props?.item?.dataSet?.formDataKey !== "email" || props?.item?.dataSet?.formDataKey !== "workEmail") ? 
              <input className='mx-1 my-1' type={'checkbox'} checked={(props?.item?.dataSet?.formDataKey === "email" || props?.item?.dataSet?.formDataKey === "workEmail") ? props?.item?.emailCommunication : props?.item?.smsCommunication} value="" onChange={(event) => handleCommunicationDetails(event.target.checked)}></input>
              : null
            }
            {props?.item?.notifyMe ? (props?.item?.dataSet?.formDataKey === "email" || props?.item?.dataSet?.formDataKey === "workEmail") && (props?.formType === "student" || props?.formType === "willdan-registration-form") ? "This is the email you will use to register and log on in the future" : 
            props?.item?.dataSet?.formDataKey === "phoneNumber" && props?.formType !== "willdan-registration-form" ?
            <Translate>Send me SMS notifications about training, certifications, career services and/or job opportunities.</Translate> : '' : ''
            }
            {/* This free training provided by NYSERDA is for individuals working directly or indirectly in clean energy. In order to verify your eligibility please use your work email address. */}
            {props?.item?.notifyMe ? (props?.item?.dataSet?.formDataKey === "email" || props?.item?.dataSet?.formDataKey === "workEmail") && props?.formType === "soulful-application" ? <Translate>This free training provided by NYSERDA is for individuals working directly or indirectly in clean energy. In order to verify your eligibility please use your work email address."</Translate> : 
            props?.item?.dataSet?.formDataKey === "phoneNumber" ?
            <Translate>Send me SMS notifications about training, certifications, career services and/or job opportunities.</Translate> : '' : ''
            }
            </p>
        </div>
     </div> 
     {props?.item?.dataSet['isError'] ? <p className='error-message-container mt-2'>{<Translate>{props?.item?.dataSet['errorMessage']}</Translate>}</p> : null}

     
     <Modal isOpen={show} onClosed={() => setShow(false)} className="modal-dialog-centered">
          <ModalHeader><Translate>Confirmation</Translate></ModalHeader>
          <ModalBody>
            <p className='text-justify'><Translate>It seems you have filled out this application before. Would you like to prefill this form with some of your previous registration details?</Translate></p>
            <div className='d-flex float-end my-2'>
              <button color='primary' className='button-primary' onClick={() => handlePrefillForm()}><Translate>Yes, Prefill My Form</Translate></button>
              <button className='mx-2 button-secondary' onClick={() => handleFillFreshForm()}><Translate>No, I'll Fill It Myself</Translate></button>
            </div>
          </ModalBody>
      </Modal>
      {/* <ToastContainer>
        
      </ToastContainer> */}
    </div>
  )
}

export default Input;
















// import React from 'react'
// import '../Inputs.css'
// const Input = ({ph}) => {
//   return (
//     <div className='ui form'>
//         <div className="field">
//             <input type="text" className='textcomponent' placeholder={ph}/>
//         </div>
//     </div>
//   )
// }

// export default Input;
