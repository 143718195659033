import React from 'react'
import '../Footer.css'
import Willimg from '../willimg.png'
const Footer = () => {
  return (
    <div className='footer'>
      {/* Updated Footer */}
      {/* <div className='w-50 d-flex justify-content-end align-items-end'>
        <img className='mx-2' src={'/life3.png'} alt="life3" />
        <img src={Willimg} alt="willdan" />
      </div> */}
      <div className='footer-image-outer-container'>
        <div className='footer-image-inner-container'>
          <img className='pointer' src={'/life3.png'} alt="life3" onClick={() => window.open('https://life3.io','_blank')} style={{cursor: 'pointer'}}/>
          <img src={Willimg} alt="" />
        </div>
      </div>
      <div className='footercontent'>
        {/* <p></p> */}
        <h5 className='mb-4'>For More information, Contact:</h5>
        {/* <br />
        <br /> */}
        <b className='mt-2'>Dwayne R. Norris</b>
        <p className='mb-3'>
          <i>Director of Outreach & Recruitement <u>CEA@soulfulsynergy.org</u></i>
        </p>
        <div className='privacy'>
          <p className='mb-0' style={{float: 'right'}}><a href='https://www.willdan.com/privacy.aspx' target='_blank' style={{color: 'white'}}><ul style={{marginBottom: '0px'}}>Willdan Privacy Policy</ul></a></p>
        </div>
          <p className='mb-0 mr-0 ml-0 mt-0' style={{float: 'right'}}>Copyright © 2023 Willdan Clean Energy Academy - All Rights are Reserved</p>
      </div>
      <p className='w-100 mt-5 mb-0 copyright text-center float-center text-font-sf'><a className='text-white' href='https://life3.io' target='_blank' style={{textDecoration: 'none'}}>Powered by Learn & Innovate for Education, Enablement, Empowerment</a></p>
    </div>
  )
}

export default Footer
