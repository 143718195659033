import React from 'react'
import { Translate } from 'react-auto-translate';
import '../Checkbox.css'
const Checkbox = (props) => {
  const handlechange=(e, option)=>{
    const {value ,checked } = e.target;
    let newarray = props?.completeQuestionData['value']
    if(checked){
      newarray.push(value)
      if (option === "None of the above" || option === "None of the above" || option === "I prefer not to say") {
        props.completeQuestionData['value'] = [option]
      } else {
        const valueArray = props.completeQuestionData['value']
        const filteredData = valueArray.filter((selectedOption) => (selectedOption !== "None of the above" && selectedOption !== "I prefer not to say"))
        props.completeQuestionData['value'] = filteredData
      }
    }
    else{
      props.completeQuestionData['value'] = newarray.filter((e) => e !== value)
      // props?.item?.setFormData({...props?.item?.formData, [props?.item?.formDataKey]:newarray.filter((e)=>e!==value)})
    }
    props?.validateData()
  }
  // console.log(props)
  return (
    <div className={`single-group-child ${props?.formType} special-component ${props?.btnDisabled ? "disabled" : ''} ${props?.item['isError'] ? 'error-in-question' : ''}`}>
        <strong className={`strongclass ${props?.formType} special-component`}><Translate>{props?.item?.question}</Translate>{props?.item?.isRequired ? <span className='mandatory'>*</span> : null}</strong>
        
        {(props?.item?.options).map((option, i) => {
                return (<div>
                    {props?.formType === "adny" && props?.completeQuestionData?.addData ? <div className='mt-2'>
                    <p><strong className='text-decoration-underline strongclass mt-2'><Translate>  <input type="checkbox" id={(option)}
                      name={props?.item?.groupName}
                      style={{marginRight: '8px'}}
                      defaultValue={option} 
                      disabled={props?.btnDisabled}
                      checked={(props?.completeQuestionData?.value).includes(option)}
                      onChange={(event) => handlechange(event, option)}
                      >
                      </input>{props?.item?.courseAdditionalData[i]?.courseTitle}</Translate></strong></p>
                    {props?.item?.courseAdditionalData[i]?.courseTimeline && (props?.item?.courseAdditionalData[i]?.courseTimeline).map(timeline => {
                        return <p className='mb-0 fs-5'><Translate>{timeline}</Translate></p>
                    })}
                    </div> : null
                   }
                    <div className='checkboxlabel' key={i} style={{marginBottom: props?.formType === "adny" && props?.completeQuestionData?.addData ? '20px' : ''}}>
                       
                       {props?.formType === "adny" && props?.completeQuestionData?.addData ? null : <>
                       <input type="checkbox" id={(option)}
                        name={props?.item?.groupName}
                        defaultValue={option} 
                        disabled={props?.btnDisabled}
                        checked={(props?.completeQuestionData?.value).includes(option)}
                        onChange={(event) => handlechange(event, option)}
                        >
                        </input>
                       <span className={`${props?.formType} option-label`}><Translate>{option}</Translate></span>
                       </>}
                    </div>
                </div>
                )
            })}

          {props?.item['isError'] ? <p className='error-message-container'><Translate>{props?.item['errorMessage']}</Translate></p> : null}
      </div>
  )
}

export default Checkbox
