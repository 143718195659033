import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App2 from './App2'
import {Translator, Translate} from 'react-auto-translate';
// import { setConfig } from 'react-google-translate'
const root = ReactDOM.createRoot(document.getElementById('root'));
 
// setConfig({
//   clientEmail: 'testingauthblla@appspot.gserviceaccount.com' ?? '',
//   privateKey: 'debaffa1751fc8851954810d9ea6b6e35b16c0a6' ?? '',
//   projectId: 'testingauthblla' ?? ''
// })

let selectedToLanguage = ''
let selectedFromLanguage = ''
const cacheProvider = {
get: (language, key) =>
    ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
    language
    ],
set: (language, key, value) => {
    const existing = JSON.parse(localStorage.getItem('translations')) || {
    [key]: {},
    };
    existing[key] = {...existing[key], [language]: value};
    localStorage.setItem('translations', JSON.stringify(existing));
},
};


if (localStorage.getItem('newFormToLanguage') !== null && localStorage.getItem('newFormToLanguage') !== undefined) {
    selectedToLanguage = localStorage.getItem('newFormToLanguage')
    if (selectedToLanguage === "en") {
        selectedFromLanguage = "es"
    } else {
        selectedFromLanguage = "en"
    }
} else {
    localStorage.setItem('newFormToLanguage', 'en')
    // localStorage.setItem('newFormFromLanguage', 'en')
    selectedFromLanguage = "en"
}

export const getGoogleTranslation = async (value) => {
    try {
      const response = await fetch(
        `https://translation.googleapis.com/language/translate/v2?source=${selectedFromLanguage}&target=${selectedToLanguage}&key=${'AIzaSyAxgfTH1nTQOILPbg3PfmRwAztgDeFWR0Q'}&q=${value}&format=text`
      );
      const jsonResponse = await response.json();
      // updateState({...state, 'name': jsonResponse.data.translations[0].translatedText})
      return jsonResponse.data.translations[0].translatedText;
    } catch (e) {
      return undefined;
    }
  }
  
  export const translatedText = async (text) => {
      const res = await getGoogleTranslation(text)
      return res
  }
  
  console.log("selectedFromLanguage: ", selectedFromLanguage, selectedToLanguage);

root.render(
    <BrowserRouter>
        <Translator
            cacheProvider={cacheProvider}
            from={'en'}
            to={selectedToLanguage}
            googleApiKey='AIzaSyAxgfTH1nTQOILPbg3PfmRwAztgDeFWR0Q'
            >
            <App2/>
        </Translator>
    </BrowserRouter>
);