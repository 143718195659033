import React from 'react'
import { Translate } from 'react-auto-translate'
import '../Inputs.css'
const WeActParagraph = () => {
  return (
    <div>
        <h3 className='centertext weact'>
          <Translate>
            Welcome to WE ACT Green Institute!
        </Translate>
        </h3>
      <p className='para weact'><Translate>
        This is a FREE Solar Installation and Green Construction Training
        </Translate>
      <br /><br />
      {/* <Translate>
        60 Hours of Clean Energy Technical Training
      </Translate>
      <br />
      <ol>
        <li className='weact'><Translate>Learn real world application of sustainability policies and standards</Translate></li>
        <li className='weact'><Translate>Understand proper use of software tools and energy audit processes</Translate></li>
        <li className='weact'><Translate>Build your portfolio of energy audit reports and analyses</Translate></li>
        <li className='weact'><Translate>Leverage experience based and service based learning opportunities</Translate></li>
        <li className='weact'><Translate>Prepare for nationally recognized industry certifications</Translate></li>
        <li className='weact'><Translate>Internship Program and Job Placement Services</Translate></li>
        <li className='weact'><Translate>Professional Development/Resume Writing and Support Services</Translate></li>
      </ol> */}

      <p className='mb-0 text-bold' style={{textDecoration: 'underline'}}><Translate>Course:</Translate></p>
      <p className='mb-2 text-bold'>
        <Translate>WE ACT Solar Installation and Green Construction Training (45 Hour Course) Start Date: 5/09/2023 - End Date: 5/19/2023 (2 Weeks) 9am - 3pm EST in Partnership with Solar1.</Translate>
      </p>
      </p>
      <p className='para weact'>
        <Translate>
          Upon completion of this application you will receive an email from our outreach team for you to schedule your interview. (Check your spam folder in case) Look forward to seeing you soon!
        </Translate>
      </p>
      <span className='success weact'>
        <Translate>
          APPLICANT INFORMATION
        </Translate>
      </span><br /><br />
    </div>
  )
}

export default WeActParagraph
