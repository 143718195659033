import { API_DOMAIN } from "../utils/constants"

export const GetResume = (id) => {
    console.log(id)
     const config = {
         method: 'GET',
         headers: {
             Authorization: 'ae5931560f8190ad8506dee91e8bc50799440b774defa1b44ff639e999c5335ff1beb906014862bbc2bfec530baf01917a83efac33384daa6d0a6a2646e5a65e7b2b3e4e3da9b582dc56731a710f1bd8712396b2fb22dbde428ed2a588e6bd927f55b6ff8e92a9f9cb1f0762cc1a7eecce227bf72f8749e861dac470320d9170',
             'Content-Type': 'application/json'
         }
     }
 
     return fetch(`${API_DOMAIN}/api_v1/s3/getURL?address=${id}`, config).then(res => {
         if (res.status === 200) {
             return res.json()
         } else {
             return null
         }
     }).catch(() => {
         return null
     })
 }
 