import React, { useState, useEffect } from "react";
// import CheckboxWithExpander from "../Components/Checkbox/CheckboxHavingChildrens";
import FormButton from "../Components/FormButton";
import SimpleRadio from "../Components/Radio/SimpleRadio";
import Checkbox from "../Inputs/Checkbox";
import { Translate } from 'react-auto-translate'
import Input from "../Inputs/Input";
import { toast, ToastContainer } from "react-toastify";
import CreatableSelect from 'react-select/creatable'
import { adnyForm } from "../Apis/adnyForm";
import SimpleCheckboxWithOther from "../Components/Checkbox/SimpleChcekBoxWithOther/simpleCheckboxWithOther";
import CheckboxWithExpander1 from "../Components/Checkbox/CheckboxHavingCheckboxChildrens";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { Badge, Modal, ModalBody, ModalHeader } from "reactstrap";
import GoogleMapAddress from "../Components/Map/map";
import MapPin from 'feather-icons-react/build/IconComponents/MapPin'
import ReactGA from 'react-ga4'
import SimpleRadioWithOther from "../Components/Radio/SimpleRadioWithOther/simpleRadioWithOther";

const ADNYApplication = () => {

    const [loader, setLoader] = useState(false)
    const [IsLoading, setIsLoading] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [currentDateTime, setCurrentDateTime] = useState(new Date());


    useEffect(() => {
      document.title = "ADNY Registration"
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
    }, [])

    useEffect(() => {
      const timer = setInterval(() => {
        setCurrentDateTime(new Date().getDate());
      }, 1000);
  
      return () => {
        clearInterval(timer);
      };
    }, []);


    const scheduledCoursesOptions =  ["September Course: Building Envelope (Virtual)", "30-Days, at your own pace online Course: Lighting Systems", "Sept/Oct Course: Building Operations & Maintenance (In-person)", "October Course:  HVAC Systems (Virtual)"] 
    const scheduledCoursesOptions1 = ["April Course - HVAC Systems (Virtual)"]
    const scheduledCoursesOptions2 = ["May Course - Building Envelope (Virtual)"]
    
    const scheduledCoursesOptionsAdditionalData1 = [
      {courseTitle: 'April Course - HVAC Systems (Virtual)', courseTimeline: ['~ Tuesday, April 16th, 9am - 5pm EDT', '~ Tuesday, April 23rd, 9am - 5pm EDT', '~ Tuesday, April 30th, 9am - 5pm EDT']},
    ]
    const scheduledCoursesOptionsAdditionalData2 = [
      {courseTitle: 'May Course - Building Envelope (Virtual)', courseTimeline: ['~ May 14th, 9AM-5PM EST', '~ May 21st, 9AM-5PM EST', '~ May 28th, 9AM-5PM EST']},
    ] 
    const scheduledCoursesOptionsAdditionalData = [{courseTitle: 'September Course: Building Envelope (Virtual)', courseTimeline: ['~ Tuesday, Sep 12th: 9AM – 5PM', '~ Tuesday, Sep 19th: 9AM – 5PM', '~ Tuesday, Sep 26th: 9AM – 5PM']},
    {courseTitle: '30-Days, at your own pace online Course: Lighting Systems', courseTimeline: ['~ September 5 - October 4', '~ Office Hours - To Offer Live Student Assistance', '~ September 8th : 11AM – 2PM', '~ September 15th: 11AM – 2PM', '~ September 22nd: 11AM – 2PM', '~ September 29th: 11AM – 2PM']},
    {courseTitle: 'Sept/Oct Course: Building Operations & Maintenance (In-person)', courseTimeline: ['~ Tuesday, Sep 26th: 9AM - 5PM', '~ Tuesday, Oct 3rd: 9AM - 5PM', '~ Tuesday, Oct 10th: 9AM - 5PM']},
    {courseTitle: 'October Course:  HVAC Systems (Virtual)', courseTimeline: ['~ October 5th: 9AM – 5PM', '~ October 12th: 9AM – 5PM', '~ October 19th: 9AM – 5PM']}]

    const [formData, setFormData] = useState({
        email: {value: '', dataSet: {question: "Email", isRequired: true, formDataKey: 'email', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', isEmail: true, userVerified: false, notifyMe: true, emailCommunication: true},     
        
        firstName: {value: '', dataSet: {question: "First Name", isRequired: true, formDataKey: 'firstName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: false}, 
        
        lastName: {value: '', dataSet: {question: "Last Name", isRequired: true, formDataKey: 'lastName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: false},
        
        language: {value: '', dataSet: {question: "Primary Language", options: ["English", "Spanish"], isRequired: true, groupName: 'language', formDataKey: 'language', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: false}, 
        
        phoneNumber: {value: '', dataSet: {question: "Telephone Number of Participant", isRequired: true, formDataKey: 'phoneNumber', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'number', disable: false},
        
        schoolName: {value: '', dataSet: {question: "Name of Parish/Regional School", isRequired: true, formDataKey: 'schoolName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: false},
        
        schoolAddress: {value: '', dataSet: {question: "Address", isRequired: true, formDataKey: 'schoolAddress', isError: false, errorMessage: ''}, type: 'simpleInputWithMap', inputType: 'text', disable: false}, 
        
        // schoolAddress: {value: '', dataSet: {question: "Parish/School Address", isRequired: true, formDataKey: 'schoolAddress', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: false},
        
        country: {value: '', dataSet: {question: "County", isRequired: true, formDataKey: 'country', isError: false, errorMessage: ''}, type: 'dropdown', inputType: 'text', disable: false},
        
        dateTime: {value: currentDateTime, dataSet: {question: "Date", isRequired: false, formDataKey: 'schoolName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true},
        
        // NA
        hadBuildingUpgrades: {value: '', dataSet: {question: "Has your building had any retrofit or upgrades in the past 2 years?", options: ["Yes", "No", "None of the above"], isRequired: true, groupName: 'hadBuildingUpgrades', formDataKey: 'hadBuildingUpgrades', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: false}, 

        hadBuildingEnergyAudit: {value: '', dataSet: {question: "Has your building had an energy audit in the past 5 years?", options: ["Yes", "No", "None of the above"], isRequired: true, groupName: 'hadBuildingEnergyAudit', formDataKey: 'hadBuildingEnergyAudit', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: false}, 

        currentRole: {value: '', dataSet: {question: "What is your current affiliation/role at the Archdiocese of New York?", options: ["Pastor", "Pastor/School Staff", "Parish Manager", "Parish Manager", "Facilities Manager", "Project Managers (Aramark)", "Operations & Maintenance Staff", "Associate Director", "Energy Management Staff", "Parishioner", "No Affiliation/Community Member"], isRequired: true, groupName: 'currentRole', formDataKey: 'currentRole', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: false}, 

        currentRoleExperience: {value: '', dataSet: {question: "How many years of experience do you have in your current role?", options: ["0-1 Year", "1-2 Year(s)", "2-3 Years", "3-5 Years", "+5 Years"], isRequired: true, groupName: 'currentRoleExperience', formDataKey: 'currentRoleExperience', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: false}, 
// NA
        pastExperienceAreas: {value: [], dataSet: {question: "Do you have past experience with any of the following areas?", options: ["Lighting & Electrical Efficiency", "HVAC & Mechanical", "Plumbing & Heating", "Carpentry & Envelope", "Solar PV", "Other Renewable Energy", "None of the above"], isRequired: true, groupName: 'pastExperienceAreas', formDataKey: 'pastExperienceAreas', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: false}, 
        
        greenIndustryCertifications: {value: [], otherValue: '', dataSet: {question: "Do you have any green industry certifications?", options: ["LEED", "BPI", "G-Pro", "AEE", "NABCEP", "OSHA", "EPA", "None of the above", "Other"], isRequired: true, groupName: 'greenIndustryCertifications', formDataKey: 'greenIndustryCertifications', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: false}, 
        
        scheduledCourses: {value: '', otherValue: '', dataSet: {question: "Which course will you be attending?", options: scheduledCoursesOptions2, courseAdditionalData: scheduledCoursesOptionsAdditionalData2, isRequired: true, groupName: 'scheduledCourses', formDataKey: 'scheduledCourses', isError: false, errorMessage: ''}, type: 'simpleRadio', addData: true, disable: false}, 
// NA
        // additionalCourse: {value: [], otherValue: '', dataSet: {question: "Indicate any additional courses you will like to attend.", options: scheduledCoursesOptions1, courseAdditionalData: scheduledCoursesOptionsAdditionalData1, isRequired: false, groupName: 'additionalCourse', formDataKey: 'additionalCourse', isError: false, errorMessage: ''}, type: 'simpleCheckbox', addData: true, disable: false}, 

        // interestedTrainingTracks : {value: [], dataSet: {question: "Select any of the Training Tracks that interest you. (Select all that apply to you)", options: [{option: "First Option", showChildren: false, subValue: [], key: 'firstOption', groupName: 'tti1', children: ['Track 1: Electrical & Lighting','Track 2: HVAC & Refrigeration','Track 3: Heating & Domestic Hot Water Systems','Track 4: Weatherization (Building Envelope)']}, {option: "Second Option", showChildren: false, subValue: [], key: 'secondOption', groupName: 'tti2', children: ['Track 1: Electrical & Lighting','Track 2: HVAC & Refrigeration','Track 3: Heating & Domestic Hot Water Systems','Track 4: Weatherization (Building Envelope)']}, {option: 'Third Option', showChildren: false, subValue: [], key: 'thirdOption', groupName: 'tti3', children: ['Track 1: Electrical & Lighting','Track 2: HVAC & Refrigeration','Track 3: Heating & Domestic Hot Water Systems','Track 4: Weatherization (Building Envelope)']}, {option: 'Fourth Option', showChildren: false, subValue: [], key: 'fourthOption', groupName: 'tti4', children: ['Track 1: Electrical & Lighting','Track 2: HVAC & Refrigeration','Track 3: Heating & Domestic Hot Water Systems','Track 4: Weatherization (Building Envelope)']}], isRequired: false, groupName: 'interestedTrainingTracks', formDataKey: 'interestedTrainingTracks', isError: false, errorMessage: ''},type: 'checkboxWithExpander1', disable: false},
        
        // freeElectives : {value: [], dataSet: {question: "Please select 1 or more of the FREE electives available to you. (Select all that apply to you)", options: [{option: "First Option", showChildren: false, subValue: [], key: 'firstOption', groupName: 'knowledge1', children: ['Benchmarking','Energy Savings Calculations','OSHA & Security Training','Fall Prevention', 'Supported Scaffolds']}, {option: "Second Option", showChildren: false, subValue: [], key: 'secondOption', groupName: 'knowledge2', children: ['Benchmarking','Energy Savings Calculations','OSHA & Security Training','Fall Prevention', 'Supported Scaffolds']}, {option: 'Third Option', showChildren: false, subValue: [], key: 'thirdOption', groupName: 'knowledge3', children: ['Benchmarking','Energy Savings Calculations','OSHA & Security Training','Fall Prevention', 'Supported Scaffolds']}, {option: 'Fourth Option', showChildren: false, subValue: [], key: 'fourthOption', groupName: 'knowledge12', children: ['Benchmarking','Energy Savings Calculations','OSHA & Security Training','Fall Prevention', 'Supported Scaffolds']}, {option: 'Fifth Option', showChildren: false, subValue: [], key: 'fifthOption', groupName: 'knowledge12', children: ['Benchmarking','Energy Savings Calculations','OSHA & Security Training','Fall Prevention', 'Supported Scaffolds']}], isRequired: false, groupName: 'freeElectives', formDataKey: 'freeElectives', isError: false, errorMessage: ''},type: 'checkboxWithExpander1', disable: false},
        
        haveAccessToComputer: {value: '', dataSet: {question: "Do you have access to a computer or tablet?", options: ["Yes", "No"], isRequired: true, groupName: 'haveAccessToComputer', formDataKey: 'haveAccessToComputer', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: false}, 
        
        haveCameraInComputer: {value: '', dataSet: {question: "Does your computer or tablet have a camera?", options: ["Yes", "No"], isRequired: true, groupName: 'haveCameraInComputer', formDataKey: 'haveCameraInComputer', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: false}, 
        
        computerOperateSkills: {value: '', dataSet: {question: "How well can you operate a computer or tablet?", options: ["Advanced Skills", "Average Skills", "Novice Skills"], isRequired: true, groupName: 'computerOperateSkills', formDataKey: 'computerOperateSkills', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: false}, 
        
        requirePlaceForTraining: {value: '', dataSet: {question: "Do you need us to provide a place for you to take the training?", options: ["Yes", "No"], isRequired: true, groupName: 'requirePlaceForTraining', formDataKey: 'requirePlaceForTraining', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: false}, 
        
        haveZoomAccount: {value: '', dataSet: {question: "Do you have a Zoom account?", options: ["Yes", "No"], isRequired: true, groupName: 'haveZoomAccount', formDataKey: 'haveZoomAccount', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: false},
        
        comfortableForZoomVideoCall: {value: '', dataSet: {question: "Are you comfortable using Zoom for a video call?", options: ["Yes", "No", "Maybe"], isRequired: true, groupName: 'comfortableForZoomVideoCall', formDataKey: 'comfortableForZoomVideoCall', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: false},
        
        comfortableForZoomChat: {value: '', dataSet: {question: "Are you comfortable using a Zoom chat?", options: ["Yes", "No", "Maybe"], isRequired: true, groupName: 'comfortableForZoomChat', formDataKey: 'comfortableForZoomChat', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: false}, 
        
        doKnowToUseBrowser: {value: '', dataSet: {question: "Do you know how to use an internet browser?", options: ["Yes", "No", "Maybe"], isRequired: true, groupName: 'doKnowToUseBrowser', formDataKey: 'doKnowToUseBrowser', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: false}, 
        
        doUseEmailAccount: {value: '', dataSet: {question: "Do you use your email account?", options: ["Yes", "No", "Maybe"], isRequired: true, groupName: 'doUseEmailAccount', formDataKey: 'doUseEmailAccount', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: false}, 
        
        anyRemark: {value: '', dataSet: {question: "Is there anything else you would like us to know?", isRequired: false, formDataKey: 'anyRemark', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: false}
    })
    // Zoom Before, Zipcode, State

  // Address Component
  const [show, setShow] = useState(false)
  const [referencess, setReference] = useState(null)
  
  const handleAdderssByMap = () => {
    setShow(true)
  }

  const handleCancel = () => {
    setShow(false)
  }

  const onLoad = ref => {
    let searchBox = null
    searchBox = ref
    setReference(ref)
  };

  const onPlacesChanged = () => {
    let selectedAddress = referencess.getPlaces()
    let addressComponentsArray = [];
    addressComponentsArray = selectedAddress[0].address_components;
    setFormData({...formData, schoolAddress: {...formData['schoolAddress'], 'value': selectedAddress[0].formatted_address}})
  };

  const setAddressByMap = (addressComponent) => {
    console.log("addressComponent: ", addressComponent);
    setFormData({...formData, schoolAddress: {...formData['schoolAddress'], 'value': addressComponent.formattedAddress}})
    // setLat(addressComponent.latitude)
    // setLng(addressComponent.longitude)
  }

    
    const validateData = () => {
        let functionReturn = true
        let validateFormData = formData
        console.log("email: ", validateFormData);
      
        Object.keys(validateFormData).forEach(eachQuestion => {
          // Checking Required ------------------------------------------------------------------------------------------------------------------
          // console.log(validateFormData[eachQuestion]['dataSet'].isRequired)
            if(validateFormData[eachQuestion]['dataSet']?.isRequired && (validateFormData[eachQuestion]['type']) ==='groupedradio'){
              const l=[]
              Object.keys(validateFormData[eachQuestion]['value']).map((i)=>{
                if(validateFormData[eachQuestion]['value'][i]!=''){
                  l.push(validateFormData[eachQuestion]['value'][i])
                }
              })
              if(l.length != Object.keys(validateFormData[eachQuestion]['value']).length){
                validateFormData[eachQuestion]['dataSet']['isError'] = true
                validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
                functionReturn = false
              }
              else{
                validateFormData[eachQuestion]['dataSet']['isError'] = false
                validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
              }
            }  else if (validateFormData[eachQuestion]['dataSet']?.isRequired && (validateFormData[eachQuestion]['type']) === 'checkboxWithExpander1') {
              const valueArray = validateFormData[eachQuestion]['value']
              if (valueArray.length !== 0) {
                for (let i = 0 ; i < valueArray.length; i++) {
                  if (valueArray[i]['value'] === "None") {
                    validateFormData[eachQuestion]['dataSet']['isError'] = false
                    validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
                    if (valueArray[i]['value'] === "None") {
                      validateFormData[eachQuestion]['value'] = [{value: 'None', subValue: ''}]
                    }
                  } else {
                    if ((valueArray[i]['subValue']).length > 0) {
                          validateFormData[eachQuestion]['dataSet']['isError'] = false
                          validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
                    } else {
                      validateFormData[eachQuestion]['dataSet']['isError'] = true
                      validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
                      functionReturn = false
                    }
                  }
                }
              } else {
                validateFormData[eachQuestion]['dataSet']['isError'] = true
                validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
                functionReturn = false
              }
            }
            // else if (eachQuestion === 'scheduledCourses') {
            //   const additionalCourseOptions = validateFormData['additionalCourse']['dataSet']['options']
            //   validateFormData['additionalCourse']['value'] = validateFormData['additionalCourse']['value'].filter((option) => additionalCourseOptions.includes(option))
              
            //   const valueArray = scheduledCoursesOptions1
            //   validateFormData['additionalCourse']['dataSet']['options'] = valueArray.filter((option) => option !== validateFormData['scheduledCourses']['value'])
            //   validateFormData['additionalCourse']['dataSet']['courseAdditionalData'] = scheduledCoursesOptionsAdditionalData1.filter((option) => option['courseTitle'] !== validateFormData['scheduledCourses']['value'])
              
            //   if (validateFormData[eachQuestion]['value'].length > 0) {
            //     validateFormData[eachQuestion]['dataSet']['isError'] = false
            //     validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
            //   } else {
            //     validateFormData[eachQuestion]['dataSet']['isError'] = true
            //     validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
            //   }
            // }
            
            else if(validateFormData[eachQuestion]['dataSet']?.isRequired && (validateFormData[eachQuestion]['value']) <=0){
              validateFormData[eachQuestion]['dataSet']['isError'] = true
              validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
              functionReturn = false
            }
            else {
              validateFormData[eachQuestion]['dataSet']['isError'] = false
              validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
            }
          // Checking Required ------------------------------------------------------------------------------------------------------------------
      
        })
        setIsLoading(true)
        setTimeout(() => {
          setIsLoading(false)
        }, 500)
        setFormData(validateFormData)
        return functionReturn
    }

    const handleSubmit = () => {
        if (validateData()) {
            try { 
                setLoader(true)
                // Interested Training Tracks
                // let interestedTrainingTracksValue = formData['interestedTrainingTracks']['value']
                // let interestedTrainingTracksObj = {
                //     firstOption: [],
                //     secondOption: [],
                //     thirdOption: [],
                //     fourthOption: [],
                // }
    
                // interestedTrainingTracksValue.forEach((item) => {
                //     interestedTrainingTracksObj[item.key] = item.subValue
                // })
                
                // // Free Electives
                // let freeElectivesValue = formData['freeElectives']['value']
                // let freeElectivesObj = {
                //     firstOption: [],
                //     secondOption: [],
                //     thirdOption: [],
                //     fourthOption: [],
                //     fifthOption: [],
                // }
                
                // freeElectivesValue.forEach((item) => {
                //     freeElectivesObj[item.key] = item.subValue
                // })
                
                adnyForm({data: {
                    email: formData['email']['value'],
                    firstName: formData['firstName']['value'],
                    lastName: formData['lastName']['value'],
                    language: formData['language']['value'],
                    phoneNumber: formData['phoneNumber']['value'],
                    schoolName: formData['schoolName']['value'],
                    schoolAddress: formData['schoolAddress']['value'],
                    country: formData['country']['value'],
                    hadBuildingUpgrades: formData['hadBuildingUpgrades']['value'],
                    hadBuildingEnergyAudit: formData['hadBuildingEnergyAudit']['value'],
                    currentRole: formData['currentRole']['value'],
                    currentRoleExperience: formData['currentRoleExperience']['value'],
                    pastExperienceAreas: (formData['pastExperienceAreas']['value']).map(item => item).join(','),
                    greenIndustryCertifications: ((formData['greenIndustryCertifications']['value']).map(item => item).join(',')) + `${formData['greenIndustryCertifications']['otherValue']}`,
                    scheduledCourses: (formData['scheduledCourses']['value']),
                    additionalCourse: null,
                    interestedTrainingTracks: null,
                    freeElectives: null,
                    haveAccessToComputer: formData['haveAccessToComputer']['value'],
                    haveCameraInComputer: formData['haveCameraInComputer']['value'],
                    computerOperateSkills: formData['computerOperateSkills']['value'],
                    requirePlaceForTraining: formData['requirePlaceForTraining']['value'],
                    haveZoomAccount: formData['haveZoomAccount']['value'],
                    comfortableForZoomVideoCall: formData['comfortableForZoomVideoCall']['value'],
                    comfortableForZoomChat: formData['comfortableForZoomChat']['value'],
                    doKnowToUseBrowser: formData['doKnowToUseBrowser']['value'],
                    doUseEmailAccount: formData['doUseEmailAccount']['value'],
                    anyRemark: formData['anyRemark']['value'],
                    formLanguage: localStorage.getItem('newFormFromLanguage') || 'en'
                }}).then(res => {
                    if (res !== null) {
                        toast.success(<Translate>Application Submitted Successfully</Translate>)
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000)
                    } else {
                        setLoader(false)
                    }
                }).catch(error => {
                    setLoader(false)
                    console.log("error: ", error);
                    toast.error(<Translate>Something went wrong</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
                })
            } catch (error) {
                console.log("error: ", error);
            }
        } else {
            toast.error(<Translate>Please Fill All The Required Fields</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
        }
    }

    const country = [
        {label: 'Bronx County', value: 'Bronx County'},
        {label: 'Manhattan (New York County)', value: 'Manhattan (New York County)'},
        {label: 'Staten Island (Richmond County)', value: 'Staten Island (Richmond County)'},
        {label: 'Brooklyn (Kings County)', value: 'Brooklyn (Kings County)'},
        {label: 'Queens County', value: 'Queens County'},
        {label: 'Westchester County', value: 'Westchester County'},
    ]

    const handleSelectCountry = (item) => {
        console.log(item);
        setFormData({...formData, country: {...formData['country'], 'value': item.value, dataSet: {...formData['country']['dataSet'], isError: false, errorMessage: ''}}})
    }

    const getContent = (questionObject, singleOrMulti) => {
        if (questionObject !== null) {
          switch(questionObject.type) {
      
            case "simpleRadio":  return (
              <SimpleRadio className={singleOrMulti} item={questionObject['dataSet']} formType="adny" btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
            )
      
            case "simpleCheckbox":  return(
              <Checkbox className={singleOrMulti} item={questionObject['dataSet']} formType="adny" btnDisabled={btnDisabled} completeQuestionData={questionObject}validateData={validateData}/>
            )
            
            case "simpleInput":  return(
              <Input className={singleOrMulti} setUserExistsData={{}} item={questionObject} btnDisabled={btnDisabled} formType="adny" setBtnDisabled={setBtnDisabled} prefillApplicationForm={() => {}} completeQuestionData={questionObject} validateData={validateData} formData={formData} setFormData={setFormData}/>
            )
            
            case "dropdown":  return (
              <div className={`single-group-child ${singleOrMulti === "single-element" ? singleOrMulti : ''} ${questionObject.dataSet['isError'] ? 'error-in-question' : ''}`}>
                <strong className='strongclass'><Translate>{questionObject.dataSet.question}</Translate><span className='mandatory'>*</span></strong>
                <div className='ui form'>
                  <div className='field'>
                      <CreatableSelect options={country} isDisabled={btnDisabled} placeholder="Select county" className='react-select' styles={{width: '100%'}} classNamePrefix='select' onChange={(item) => handleSelectCountry(item)}/>
                  </div>
              </div> 
              {formData['country']['dataSet']['isError'] ? <p className='error-message-container'><Translate>{questionObject['dataSet']['errorMessage']}</Translate></p> : null}
              </div>
            )

            case "checkboxWithExpander1":return(
                <CheckboxWithExpander1 className={singleOrMulti} coursesList={[]} item={questionObject} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
            )

            case "radioWithOther":  return (
              <SimpleRadioWithOther className={singleOrMulti} item={questionObject} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
              // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
            )
      
            case "checkboxWithOther":  return (
                <SimpleCheckboxWithOther className={singleOrMulti} item={questionObject} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
                // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
            )

            case "simpleInputWithMap": return (
              <div className={`single-group-child ${singleOrMulti === "single-element" ? singleOrMulti : ''} ${questionObject.dataSet['isError'] ? 'error-in-question' : ''}`}>
              <strong className='strongclass'><Translate>{questionObject.dataSet.question}</Translate><span className='mandatory'>*</span>
              {!btnDisabled ? 
              <Badge role={'button'} className="float-end" color='warning' style={{color: 'black'}} onClick={() => handleAdderssByMap()}><MapPin className="primary" size={18} stroke="black"/><Translate>Locate on Map</Translate></Badge>
                // <span className="fs-4" role={'button'} onClick={() => handleAdderssByMap()}>{`(Click to Locate on Map)`}</span>
                : null
              }
              </strong> 
              <div className='ui form'>
                <div className='field'>
                <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
                    <input type="text" value={formData.schoolAddress.value} disabled={btnDisabled} className="form-control" name="address" id="address" placeholder="Enter Address" onChange={(event) => setFormData({...formData, schoolAddress: {...formData['schoolAddress'], 'value': event.target.value}})}/>
                </StandaloneSearchBox>
                </div>
            </div> 
            {questionObject['dataSet']['isError'] ? <p className='error-message-container'><Translate>{questionObject['dataSet']['errorMessage']}</Translate></p> : null}
            </div>
            )
      
          }
        }
    }

    return (
        <div className='card'>
            <div className='formbg'>
            <span className='formheading'><Translate>ADNY Registration</Translate></span><br /><br />
            <p className="para">
            <Translate>This is a FREE energy efficiency technical training, combined with guided hands-on project implementation. The program focuses on energy efficiency education, local laws, policy, cost benefit and implementation assistance on buildings within the ADNY portfolio.  This offering is available to all ADNY and Trustees of St Patrick's Cathedral employees and contractors.</Translate>
            </p>

            <p><strong className="strongclass para" style={{color: 'black'}}><Translate>24 Hours of Clean Energy Technical Training</Translate></strong></p>
            
            <p className="para"><strong style={{color: 'black'}}><Translate>+ 6 Hours Hands-on Project Implementation</Translate></strong>
                <li className="mx-2 mt-2">
                <Translate>Advance your knowledge of energy efficiency measures</Translate>
                </li>
                
                <li className="mx-2">
                <Translate>Learn real world application of sustainability policies, standards and codes</Translate>
                </li>
                
                <li className="mx-2">
                <Translate>Implement cost saving projects and energy retrofits for ADNY buildings</Translate>
                </li>
                
                <li className="mx-2">
                <Translate>Understand proper use of energy audit processes</Translate>
                </li>
                
                <li className="mx-2">
                <Translate>Prepare for nationally recognized industry certifications</Translate>
                </li>

             </p>
            <div className='single-elements-container'>
                { 
                Object.keys(formData).map((eachQuestion, i) => {
                if (i < 9) {
                    return (
                    <div>
                        {getContent(formData[eachQuestion], 'single-element')}
                    </div>
                    )
                }
                })
                }
            </div>
            {
            Object.keys(formData).map((eachQuestion, i) => {
            if (i >= 9) {
                return (
                <div>
                    {getContent(formData[eachQuestion])}
                </div>
                )
            }
            })
            }
                <div className='single-submit-button'>
                    <FormButton text="Submit" handleSubmit={() => handleSubmit()} loading={loader}/>
                </div>
            </div>
            {/* <Modal isOpen={show} onClosed={() => handleCancel()} className="modal-dialog-centered">
            <ModalHeader><Translate>{modalName}</Translate></ModalHeader>
            <ModalBody>
                <div>
                    <div className='d-flex flex-column justify-evenly align-items-center'>
                    <img src={FormSubmitted} style={{height: '100px', width: '100px'}}></img>
                    <p className='text-center fs-3'><Translate>Thank You!! Your response has been submitted</Translate></p>
                    </div>
                </div>
            </ModalBody>
        </Modal> */}

            <Modal isOpen={show} onClosed={() => handleCancel()}>
                <ModalHeader><Translate>Mark Address</Translate></ModalHeader>
                <ModalBody>
                    <GoogleMapAddress handleCancel={handleCancel} setAddressByMap={setAddressByMap}></GoogleMapAddress>
                </ModalBody>
            </Modal>
        <ToastContainer>
        
      </ToastContainer>
    </div>
    )
}

export default ADNYApplication