import React, { useEffect, useState } from 'react'
import Checkbox from '../Inputs/Checkbox'
import FormButton from '../Components/FormButton'
import '../Inputs.css'
import '../Radio.css'
import SimpleRadio from '../Components/Radio/SimpleRadio'
// import { ToastContainer, toast } from 'react-toastify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleRadioWithOther from '../Components/Radio/SimpleRadioWithOther/simpleRadioWithOther'
import SimpleCheckboxWithOther from '../Components/Checkbox/SimpleChcekBoxWithOther/simpleCheckboxWithOther'
import { EMAIL_REGEX } from '../utils/constants'
import Input from '../Inputs/Input'
import FormSubmitted from './../assets/submitted.png'
import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import GroupedRadio from '../Inputs/GroupedRadio'
import SingleLineRadio from '../Inputs/SingleLineRadio'
import DOB from '../Inputs/Dob'
import moment from 'moment/moment'
import {NyserdaPostTrainingFormApplications,checkUserEmail} from '../Apis/nyserdaPostSurvey'
import {GetCoursesList} from '../Apis/generalApis'
import Dropdown from '../Inputs/Dropdown'
import TwosideRadio from '../Inputs/TwosideRadio'
import { Translate } from 'react-auto-translate'
import CheckboxWithExpander from '../Components/Checkbox/CheckboxHavingChildrens'
import ReactGA from 'react-ga4'
import getIp from 'react-public-ip';
import { UAParser } from 'ua-parser-js'

const Posttraining = () => {
  const [loader, setLoader] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [show, setShow] = useState(false)
  const [modalName, setModalName] = useState('')
  const [coursesList, setCoursesList] = useState([])
  const [userExistsData, setUserExistsData] = useState({})

  // User System Info
  const [userSystemInfo, setUserSystemInfo] = useState({info: "", ip: ""})

  useEffect(() => {
      // setFormData({...formData, course: dName})
      document.title = "NYSERDA Post Survey Form"
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });

      try {
          GetCoursesList().then((res) => {
              if(res !== null) {
                  setCoursesList(res.data)
              } else {
                  setCoursesList([])
              }
          })
      }catch (error) {
          setCoursesList([])
          toast.error(<Translate>Something went wrong</Translate>, {position: 'top-right', autoClose: 3000})
      }

      async function getIPAddress() {
        const ipv4 = await getIp.v4() || "";
        const parser = new UAParser();
        // console.log("ipv4: ", ipv4);
        setUserSystemInfo({...userSystemInfo, info: parser.getResult(), ip: ipv4})
      }
      getIPAddress()
  }, [])

  const [formData, setFormData] = useState(
  {
      email: {value: '',studentMoodleId:'', dataSet: {question: "Email", isRequired: true, formDataKey: 'email', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', isEmail: true, userVerified: false,placeholder:'Email'}, 
       
      fName: {value: '', dataSet: {question: "First Name", isRequired: true, formDataKey: 'fName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true,placeholder:'First Name'}, 

      lName: {value: '', dataSet: {question: "Last Name", isRequired: true, formDataKey: 'lName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true,placeholder:'Last Name'},

      // jobTitle: {value: '', dataSet: {question: 'Current or last job title or position held:', isRequired: true, formDataKey: 'jobTitle', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', placeholder:'Job Title'},

      course: {value: '', dataSet: {question: "Course Title", isRequired: true, formDataKey: 'course', isError: false, errorMessage: ''}, type: 'dropdown', disable: true,placeholder:'Please Select Course'},

      dateOfCourse: {value:'',dataSet:{question:"Date of Course",isRequired:true, formDataKey:'dateOfCourse',isError: false, errorMessage: ''}, type: 'dob', disable: true},

      TrainingOrganization: {value:'',dataSet:{question:"Training Organization",isRequired:true, formDataKey:'TrainingOrganization',isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true,placeholder:'Training Organization'},

      TrainerName: {value:'',dataSet:{question:"Trainer Name(s)",isRequired:true, formDataKey:'TrainerName',isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true,placeholder:'Trainer Name(s)'},

      // reasonParticipation: {value: [], otherValue: '', dataSet: {question: "Why did you participate in this training? (check all that apply)", options: ['To prepare for industry recognized certifications and or receive CEUs','Looking to transition from another career into a career in the clean energy industry','Seeking job training, career advancement through job placement, internships or apprenticeships','To help me get a better job in the industry better than the one I currently have','At the recommendation/request of client, customer or employer','Personal interest or to acquire new skills','Other'], isRequired: true, groupName: 'reasonParticipation', formDataKey: 'reasonParticipation', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true}, 
      
      // currentEmploymentStatus: {value: '', dataSet: {question: "Which of the following best describes your current employment status?", options: ["Employed","Unemployed",'Self Employed','Other'], isRequired: true, groupName: 'currentEmploymentStatus', formDataKey: 'currentEmploymentStatus', isError: false, errorMessage: ''}, type: 'radioWithOther', disable: true}, 
      
      // durationUnemployment: {value: '', dataSet: {question: "If unemployed, how long how you been unemployed?", options: ["Less than 6 months","6 months - 12 months",'1 year - 2 years','2 years or more','N/A - I am already employed'], isRequired: true, groupName: 'durationUnemployment', formDataKey: 'durationUnemployment', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
     
      // considerUnderEmployed: {value: '', dataSet: {question: "If employed, would you consider yourself underemployed? (Underemployed definition -Underemployment is a condition in which workers are employed in less than full-time or regular jobs or insufficient jobs for their training or economic needs. Also, underemployment is a worker's underuse because a job does not use the skills of the worker, i.e. part-time, or leaves the worker idle)", options: ["Yes","No",'Unsure','N/A - I am unemployed','N/A - I am self-employed'], isRequired: false, groupName: 'considerUnderEmployed', formDataKey: 'considerUnderEmployed', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
     
      // seekingEmployment: {value: '', dataSet: {question: "Are you currently actively looking for employment opportunities?", options: ["Yes","No"], isRequired: false, groupName: 'seekingEmployment', formDataKey: 'seekingEmployment', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
     
      // seekingEmploymentSupport: {value: '', dataSet: {question: "If seeking employment, would you be interested in career services and job placement support?", options: ["Yes","No",'Maybe','N/A - I am already employed'], isRequired: false, groupName: 'seekingEmploymentSupport', formDataKey: 'seekingEmploymentSupport', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
     
      // skills_interests: {value: {
      //   energyEfficiency: '',
      //   renewableEnergy: '',
      //   residentialConstruction: '',
      //   commercialConstruction: '',
      //   shellEnvelopeImprovements: '',
      //   plumbing: '',
      //   electrical: '',
      //   architectureEngineeringRelated: '',
      //   hvac: '',
      //   pv: '',
      //   solarThermalTechnician: '',
      //   other: '',
      // }, dataSet: {question: "Rate your knowledge and interest in the following topics.", options: ['No Skill','Introductory Level Skill','General Level Skill','Advanced Level Skill','Greatest Interest'],choices:{'Energy Efficiency':'','Renewable Energy':'','General Residential Construction':'',"General Commercial Construction":'',"Building Shell/Envelope Improvements":'',"Plumbing":'',"Electrical":'',"Architecture, Engineering or Related":'',"HVAC Installation/Technician":'',"Photovoltaic (PV) Technician":'',"Solar Thermal Technician":'',"Other: e.g. Storage, Heat Pump":''}, isRequired: true, groupName: 'skills_interests', formDataKey: 'skills_interests', isError: false, errorMessage: ''}, type: 'groupedradio', disable: true}, 

      skills_interests : {value: [], dataSet: {question: "Please indicate your current skill level in the following areas:", options: [{option: "Energy Efficiency", showChildren: false, subValue: '', key: 'energyEfficiency', groupName: 'knowledge1', children: ['Introductory Level Skill','General Level Skill','Advanced Level Skill']}, {option: "Renewable Energy", showChildren: false, subValue: '', key: 'renewableEnergy', groupName: 'knowledge2', children: ['Introductory Level Skill','General Level Skill','Advanced Level Skill']}, 
      // {option: 'General Residential Construction', showChildren: false, subValue: '', key: 'residentialConstruction', groupName: 'knowledge3', children: ['No Skills','Introductory Level Skill','General Level Skill','Advanced Level Skill']}, 
      // {option: 'General Commercial Construction', showChildren: false, subValue: '', key: 'commercialConstruction', groupName: 'knowledge12', children: ['No Skills','Introductory Level Skill','General Level Skill','Advanced Level Skill']}, 
      {option: 'Building Shell/Envelope Improvements', showChildren: false, subValue: '', key: 'shellEnvelopeImprovements', groupName: 'knowledge4', children: ['Introductory Level Skill','General Level Skill','Advanced Level Skill']},
      // {option: 'Plumbing', showChildren: false, subValue: '', key: 'plumbing', groupName: 'knowledge5', children: ['No Skills','Introductory Level Skill','General Level Skill','Advanced Level Skill']}, 
      // {option: 'Electrical', showChildren: false, subValue: '', key: 'electrical', groupName: 'knowledge6', children: ['No Skills','Introductory Level Skill','General Level Skill','Advanced Level Skill']}, 
      // {option: 'Architecture, Engineering or Related', showChildren: false, subValue: '', key: 'architectureEngineeringRelated', groupName: 'knowledge7', children: ['No Skills','Introductory Level Skill','General Level Skill','Advanced Level Skill']}, 
      {option: 'HVAC Installation/Technician', showChildren: false, subValue: '', key: 'hvac', groupName: 'knowledge8', children: ['Introductory Level Skill','General Level Skill','Advanced Level Skill']}, {option: 'Photovoltaic (PV) Technician', showChildren: false, subValue: '', key: 'pv', groupName: 'knowledge9', children: ['Introductory Level Skill','General Level Skill','Advanced Level Skill']}, {option: 'Solar Thermal Technician', showChildren: false, subValue: '', key: 'solarThermalTechnician', groupName: 'knowledge10', children: ['Introductory Level Skill','General Level Skill','Advanced Level Skill']}, {option: 'Other: e.g. Storage, Heat Pump', showChildren: false, subValue: '', key: 'other', groupName: 'knowledge11', children: ['Introductory Level Skill','General Level Skill','Advanced Level Skill']}], isRequired: true, groupName: 'skills_interests', formDataKey: 'skills_interests', isError: false, errorMessage: ''},type: 'checkboxWithExpander', disable: true}, 

      interest: {value: [], otherValue: '', dataSet: {question: "Please select all technical fields that interest you:", options: ['Energy Efficiency','Renewable Energy',
      // "General Residential Construction","General Commercial Construction",
      "Building Shell/Envelope Improvements",
      // "Plumbing","Electrical","Architecture, Engineering or Related",
      "HVAC Installation/Technician","Photovoltaic (PV) Technician","Solar Thermal Technician","Other"], isRequired: true, groupName: 'interest', formDataKey: 'interest', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true}, 

      // highestEducation: {value: '', dataSet: {question: "What is the highest level of academic education you have completed? (Select the one that most applies to you)", options: ["Less than High School", "High School (or GED)", "Two-Year College", "Four-Year College", "Post-Graduate Degree (such as Master or Doctorate)"], isRequired: true, groupName: 'highestEducation', formDataKey: 'highestEducation', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 

      // recentlyGraduated: {value: '', dataSet: {question: "Are you currently enrolled or have you graduated from one of these institutions in the past 12 months?(Select the one that most applies to you)", options: ["Vocational/Technical School", "High School (or GED)", "Two-Year College", "Four-Year College", "Post-Graduate Degree (such as Master or Doctorate)","None of the above"], isRequired: true, groupName: 'recentlyGraduated', formDataKey: 'recentlyGraduated', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 

      // newInfoPercentage: {value: '', dataSet: {question: "What percentage of information was new to you? (1 = 0%, 2 = 20%, 3 = 40%, 4 = 60%, 5 = 80%, 6 = 100%)", options: ['1','2','3','4','5','6'],choices:['0%','100%'], isRequired: true, groupName: 'newInfoPercentage', formDataKey: 'newInfoPercentage', isError: false, errorMessage: ''}, type: 'twosideRadio', disable: true},
      
      technicalRigorRating: {value: '', dataSet: {question: "The technical rigor of the content was: (Select the one that most applies to you)", options: ["Too easy for me","Appropriate for me","Over my head"], isRequired: true, groupName: 'technicalRigorRating', formDataKey: 'technicalRigorRating', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},       

      valueInfoShared: {value: '', dataSet: {question: "The information shared throughout the training was valuable to me. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'valueInfoShared', formDataKey: 'valueInfoShared', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      trainingMeetExpectation: {value: '', dataSet: {question: "The training met my expectations. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'trainingMeetExpectation', formDataKey: 'trainingMeetExpectation', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      understandingImprovement:{value: '', dataSet: {question: "My understanding of the topic on clean energy principles and fundamentals improved. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'understandingImprovement', formDataKey: 'understandingImprovement', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      knowledgeApplication: {value: '', dataSet: {question: "I will be able to apply the education I learned. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'knowledgeApplication', formDataKey: 'knowledgeApplication', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      courseStructureRating: {value: '', dataSet: {question: "The course was organized in a manner that helped me understand the underlying concepts. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'courseStructureRating', formDataKey: 'courseStructureRating', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},       

      rateInstructorKnowledge: {value: '', dataSet: {question: "The instructor was knowledgeable about this topic. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'rateInstructorKnowledge', formDataKey: 'rateInstructorKnowledge', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      instructorEngagingRating: {value: '', dataSet: {question: "The instructor made the class engaging. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'instructorEngagingRating', formDataKey: 'instructorEngagingRating', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      instructorExplanationRating: {value: '', dataSet: {question: "The instructor explained the material well. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'instructorExplanationRating', formDataKey: 'instructorExplanationRating', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      instructorPreparenessRating: {value: '', dataSet: {question: "The instructor was well prepared for the class. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'instructorPreparenessRating', formDataKey: 'instructorPreparenessRating', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      instructorInterestRating: {value: '', dataSet: {question: "The instructor showed an interest in helping the students learn. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'instructorInterestRating', formDataKey: 'instructorInterestRating', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      trainingTimeRating: {value: '', dataSet: {question: "The amount of time required for the training was manageable. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'trainingTimeRating', formDataKey: 'trainingTimeRating', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      softwareRating: {value: '', dataSet: {question: "I am satisfied with the technology and software I am using for remote learning. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'softwareRating', formDataKey: 'softwareRating', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      rateClassLevel: {value: '', dataSet: {question: "I have received the classroom support that I need. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'rateClassLevel', formDataKey: 'rateClassLevel', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      technicalSupportRating: {value: '', dataSet: {question: "I have received the technical support that I needed. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'technicalSupportRating', formDataKey: 'technicalSupportRating', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      localConvenientRating: {value: '', dataSet: {question: "The location of the course was convenient for me. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'courseExperience', formDataKey: 'courseExperience', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      timingConvenienceRating: {value: '', dataSet: {question: "The time during which the course was offered was convenient to me. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'timingConvenienceRating', formDataKey: 'timingConvenienceRating', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      trainingAffordability: {value: '', dataSet: {question: "The training was affordable. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree","Agree","Neutral","Disagree","Strongly Disagree"], isRequired: true, groupName: 'trainingAffordability', formDataKey: 'trainingAffordability', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      courseExperience: {value: [], dataSet: {question: "Which response best describes the experience you had for this course, given your experience and career goals? (check all that applies)", options: ["The course taught me new things I wanted to learn","The course provided a refresher on things I have learned before","The course was over my head","The course was definitely over my head","The course was definitely below my level","I am not sure"], isRequired: true, groupName: 'courseExperience', formDataKey: 'courseExperience', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true},

      // rateClassLevel: {value: '', dataSet: {question: "Please rate the level of classroom and technical support your received:", options: ['1','2','3','4','5'],choices:['Poor','Excellent'], isRequired: true, groupName: 'rateClassLevel', formDataKey: 'rateClassLevel', isError: false, errorMessage: ''}, type: 'twosideRadio', disable: true},

      willRecommend: {value: '', dataSet: {question: "I will recommend this training to a colleague or a friend. Rate the extent of your agreement with this sentence:", options: ["Strongly Agree", "Agree", "Neutral", 'Disagree','Strongly Disagree'], isRequired: true, groupName: 'willRecommend', formDataKey: 'willRecommend', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

      missedTopicSugesstion: {value: '', dataSet: {question: "What would you have liked to learn that you did not learn during the course?", isRequired: true, formDataKey: 'missedTopicSugesstion', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true,placeholder:''}, 

      topicMostValuable: {value: '', dataSet: {question: "What was the most valuable piece of information you received?", isRequired: true, formDataKey: 'topicMostValuable', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true,placeholder:''}, 

      suggestion: {value: '', dataSet: {question: "What could we do to improve your experience, and/or is there anything else you would like us to know?", isRequired: true, formDataKey: 'suggestion', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true,placeholder:''}, 

      }
  )
  
  const [IsLoading, setIsLoading] = useState(false)
  const validateData = () => {
  let functionReturn = true
  let validateFormData = formData
  // console.log("email: ", email, formData['email']['dataSet']['isRequired'] && (formData['email']['dataSet']['formDataKey'] === "email"), validateFormData);

  Object.keys(validateFormData).forEach(eachQuestion => {
    // Checking Required ------------------------------------------------------------------------------------------------------------------
    // console.log(validateFormData[eachQuestion]['dataSet'].isRequired)
    if(validateFormData[eachQuestion]['dataSet']?.isRequired && (validateFormData[eachQuestion]['type']) ==='groupedradio'){
      const l=[]
      Object.keys(validateFormData[eachQuestion]['value']).map((i)=>{
        if(validateFormData[eachQuestion]['value'][i]!=''){
          l.push(validateFormData[eachQuestion]['value'][i])
        }
      })
      if(l.length != Object.keys(validateFormData[eachQuestion]['value']).length){
        validateFormData[eachQuestion]['dataSet']['isError'] = true
        validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
        functionReturn = false
      }
      else{
        validateFormData[eachQuestion]['dataSet']['isError'] = false
        validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
      }
    } else if (validateFormData[eachQuestion]['dataSet'].isRequired && (validateFormData[eachQuestion]['type']) === 'checkboxWithExpander') {
      const valueArray = validateFormData[eachQuestion]['value']
      if (valueArray.length !== 0) {
        for (let i = 0 ; i < valueArray.length; i++) {
          if (valueArray[i]['value'] === "None") {
            validateFormData[eachQuestion]['dataSet']['isError'] = false
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
            if (valueArray[i]['value'] === "None") {
              validateFormData[eachQuestion]['value'] = [{value: 'None', subValue: ''}]
            }
          } else {
            if ((valueArray[i]['subValue']).length > 0) {
                  validateFormData[eachQuestion]['dataSet']['isError'] = false
                  validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
            } else {
              validateFormData[eachQuestion]['dataSet']['isError'] = true
              validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
              functionReturn = false
            }
          }
        }
      } else {
        validateFormData[eachQuestion]['dataSet']['isError'] = true
        validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
        functionReturn = false
      }
    }
      
      else if(validateFormData[eachQuestion]['dataSet']['isRequired'] && (validateFormData[eachQuestion]['value']) <= 0){
        
        validateFormData[eachQuestion]['dataSet']['isError'] = true
        validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
        functionReturn = false
      }
      else {
        validateFormData[eachQuestion]['dataSet']['isError'] = false
        validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
      }
    // Checking Required ------------------------------------------------------------------------------------------------------------------

    const updatedQuestionsKey = ['trainingMeetExpectation', 'rateInstructorKnowledge', 'understandingImprovement', 'knowledgeApplication', 'valueInfoShared']
    const answersForUpdatedOptionsQuestion = ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
    
    if (updatedQuestionsKey.includes(eachQuestion)) {
      if (!answersForUpdatedOptionsQuestion.includes(validateFormData[eachQuestion]['value'])) {
        validateFormData[eachQuestion]['dataSet']['isError'] = true
        validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
        functionReturn = false
      } else {
        validateFormData[eachQuestion]['dataSet']['isError'] = false
        validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
        functionReturn = true
      }
    }
  })
  setIsLoading(true)
  setTimeout(() => {
    setIsLoading(false)
  }, 500)
  setFormData(validateFormData)
  return functionReturn
  }

  const handleReloadForm = () => {
    setModalName('Thank You')
    setTimeout(() => {
      window.location.reload()
    }, 3000)
  }
  const handleCancel = () => {
    setShow(false)
  }
    
  const handleSubmit = () => {

  if (validateData()) {
    setLoader(true);
    let dateString = new Date();
    dateString = moment(dateString).format('YYYY-MM-DD');

    // Skill
    let skillLevel = formData['skills_interests']['value']
    let obj = {
      energyEfficiency: '',
      renewableEnergy: '',
      residentialConstruction: '',
      commercialConstruction: '',
      shellEnvelopeImprovements: '',
      plumbing: '',
      electrical: '',
      architectureEngineeringRelated: '',
      hvac: '',
      pv: '',
      solarThermalTechnician: '',
      other: '',
    }

    skillLevel.forEach((item) => {
      obj[item.key] = (item.subValue).length > 0 ? item.subValue : 'None'
    })

    NyserdaPostTrainingFormApplications({data: {
      email: formData['email']['value'],
      fName: formData['fName']['value'],
      lName: formData['lName']['value'],
      jobTitle: '',
      course: formData['course']['value'],
      dateOfCourse: formData['dateOfCourse']['value'],
      TrainingOrganization: formData['TrainingOrganization']['value'],
      TrainerName: formData['TrainerName']['value'],
      // seekingEmployment: formData['seekingEmployment']['value'],
      skills_interests: {
        energyEfficiency: obj['energyEfficiency'],
        renewableEnergy: obj['renewableEnergy'],
        // residentialConstruction: obj['residentialConstruction'],
        // commercialConstruction: obj['commercialConstruction'],
        shellEnvelopeImprovements: obj['shellEnvelopeImprovements'],
        // plumbing: obj['plumbing'],
        // electrical: obj['electrical'],
        // architectureEngineeringRelated: obj['architectureEngineeringRelated'],
        hvac: obj['hvac'],
        pv: obj['pv'],
        solarThermalTechnician: obj['solarThermalTechnician'],
        other: obj['other']
      },
      valueInfoShared: formData['valueInfoShared']['value'],
      interest: (formData['interest']['value']).map(item => item).join(','),
      // newInfoPercentage: formData['newInfoPercentage']['value'],
      technicalRigorRating: formData['technicalRigorRating']['value'],
      courseStructureRating: formData['courseStructureRating']['value'],
      instructorEngagingRating: formData['instructorEngagingRating']['value'],
      instructorExplanationRating: formData['instructorExplanationRating']['value'],
      instructorPreparenessRating: formData['instructorPreparenessRating']['value'],
      instructorInterestRating: formData['instructorInterestRating']['value'],
      trainingTimeRating: formData['trainingTimeRating']['value'],
      softwareRating: formData['softwareRating']['value'],
      rateClassLevel: formData['rateClassLevel']['value'],
      technicalSupportRating: formData['technicalSupportRating']['value'],
      localConvenientRating: formData['localConvenientRating']['value'],
      timingConvenienceRating: formData['timingConvenienceRating']['value'],
      trainingAffordability: formData['trainingAffordability']['value'],
      courseExperience: (formData['courseExperience']['value']).map(item => item).join(','),
      trainingMeetExpectation: formData['trainingMeetExpectation']['value'],
      rateInstructorKnowledge: formData['rateInstructorKnowledge']['value'],
      understandingImprovement: formData['understandingImprovement']['value'],
      knowledgeApplication: formData['knowledgeApplication']['value'],
      willRecommend: formData['willRecommend']['value'],
      missedTopicSugesstion: formData['missedTopicSugesstion']['value'],
      topicMostValuable: formData['topicMostValuable']['value'],
      suggestion: formData['suggestion']['value'],
      versionName: 'new_2023',
      formLanguage: localStorage.getItem('newFormFromLanguage') || 'en',
      studentMoodleId: formData?.email?.studentMoodleId || '',
      userData: userSystemInfo
    }}).then(res => {
    if (res !== null) {
      setShow(true)
      setModalName("Verify Your Email")
      setLoader(false)
      toast.success(<Translate>{'Application Submitted Successfully'}</Translate>)
      handleReloadForm()
    }
    }).catch(error => {
      console.log("error: ", error);
      setLoader(false)
      toast.error(<Translate>Something went wrong</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
    })

  } else {
    toast.error(<Translate>{'Please Fill All The Required Fields'}</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
  }

  console.log(formData)

  }

  // Prefil Form Data
  const prefillApplicationForm = (data) => {
    const userData = data.attributes
    console.log("prefillApplicationForm: ", userData);
    // console.log("questionOptions: ", questionOptions);

    const answersForUpdatedOptionsQuestion = ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']

    setFormData({...formData, 
      fName: {...formData['fName'], 'value': userData.fName, dataSet: {...formData['fName']['dataSet'], isError: false, errorMessage: ''}},
      lName: {...formData['lName'], 'value': userData.lName, dataSet: {...formData['lName']['dataSet'], isError: false, errorMessage: ''}},
      jobTitle: {...formData['jobTitle'], 'value': '', dataSet: {...formData['jobTitle']['dataSet'], isError: false, errorMessage: ''}},
      course: {...formData['course'], 'value': userData.course, dataSet: {...formData['course']['dataSet'], isError: false, errorMessage: ''}},
      dateOfCourse: {...formData['dateOfCourse'], 'value': userData.dateOfCourse, dataSet: {...formData['dateOfCourse']['dataSet'], isError: false, errorMessage: ''}},
      TrainingOrganization: {...formData['TrainingOrganization'], 'value': userData.TrainingOrganization, dataSet: {...formData['TrainingOrganization']['dataSet'], isError: false, errorMessage: ''}},
      TrainerName: {...formData['TrainerName'], 'value': userData.TrainerName, dataSet: {...formData['TrainerName']['dataSet'], isError: false, errorMessage: ''}},
      // seekingEmployment: {...formData['seekingEmployment'], 'value': userData.seekingEmployment, dataSet: {...formData['seekingEmployment']['dataSet'], isError: false, errorMessage: ''}},
      skills_interests: {...formData['skills_interests'], 'value': userData.skills_interests, dataSet: {...formData['skills_interests']['dataSet'], isError: false, errorMessage: ''}},
      valueInfoShared: {...formData['valueInfoShared'], 'value': userData.valueInfoShared, dataSet: {...formData['valueInfoShared']['dataSet'], isError: !answersForUpdatedOptionsQuestion.includes(formData['valueInfoShared']['value']), errorMessage: !answersForUpdatedOptionsQuestion.includes(formData['valueInfoShared']['value']) ? 'Required' : ''}},
      // newInfoPercentage: {...formData['newInfoPercentage'], 'value': userData.newInfoPercentage, dataSet: {...formData['newInfoPercentage']['dataSet'], isError: false, errorMessage: ''}},
      technicalRigorRating: {...formData['technicalRigorRating'], 'value': userData.technicalRigorRating, dataSet: {...formData['technicalRigorRating']['dataSet'], isError: false, errorMessage: ''}},
      courseStructureRating: {...formData['courseStructureRating'], 'value': userData.courseStructureRating, dataSet: {...formData['courseStructureRating']['dataSet'], isError: false, errorMessage: ''}},
      instructorEngagingRating: {...formData['instructorEngagingRating'], 'value': userData.instructorEngagingRating, dataSet: {...formData['instructorEngagingRating']['dataSet'], isError: false, errorMessage: ''}},
      instructorExplanationRating: {...formData['instructorExplanationRating'], 'value': userData.instructorExplanationRating, dataSet: {...formData['instructorExplanationRating']['dataSet'], isError: false, errorMessage: ''}},
      instructorPreparenessRating: {...formData['instructorPreparenessRating'], 'value': userData.instructorPreparenessRating, dataSet: {...formData['instructorPreparenessRating']['dataSet'], isError: false, errorMessage: ''}},
      instructorInterestRating: {...formData['instructorInterestRating'], 'value': userData.instructorInterestRating, dataSet: {...formData['instructorInterestRating']['dataSet'], isError: false, errorMessage: ''}},
      trainingTimeRating: {...formData['trainingTimeRating'], 'value': userData.trainingTimeRating, dataSet: {...formData['trainingTimeRating']['dataSet'], isError: false, errorMessage: ''}},
      softwareRating: {...formData['softwareRating'], 'value': userData.softwareRating, dataSet: {...formData['softwareRating']['dataSet'], isError: false, errorMessage: ''}},
      rateClassLevel: {...formData['rateClassLevel'], 'value': userData.rateClassLevel, dataSet: {...formData['rateClassLevel']['dataSet'], isError: false, errorMessage: ''}},
      technicalSupportRating: {...formData['technicalSupportRating'], 'value': userData.technicalSupportRating, dataSet: {...formData['technicalSupportRating']['dataSet'], isError: false, errorMessage: ''}},
      localConvenientRating: {...formData['localConvenientRating'], 'value': userData.localConvenientRating, dataSet: {...formData['localConvenientRating']['dataSet'], isError: false, errorMessage: ''}},
      courseExperience: {...formData['courseExperience'], 'value': userData.courseExperience ?  (userData.courseExperience).split(',').map(item => item.trim()) : [], dataSet: {...formData['courseExperience']['dataSet'], isError: false, errorMessage: ''}},
      timingConvenienceRating: {...formData['timingConvenienceRating'], 'value': userData.timingConvenienceRating, dataSet: {...formData['timingConvenienceRating']['dataSet'], isError: false, errorMessage: ''}},
      trainingAffordability: {...formData['trainingAffordability'], 'value': userData.trainingAffordability, dataSet: {...formData['trainingAffordability']['dataSet'], isError: false, errorMessage: ''}},
      // courseExperience: {...formData['courseExperience'], 'value': userData.courseExperience, dataSet: {...formData['courseExperience']['dataSet'], isError: false, errorMessage: ''}},
      trainingMeetExpectation: {...formData['trainingMeetExpectation'], 'value': userData.trainingMeetExpectation, dataSet: {...formData['trainingMeetExpectation']['dataSet'], isError: !answersForUpdatedOptionsQuestion.includes(formData['trainingMeetExpectation']['value']), errorMessage: !answersForUpdatedOptionsQuestion.includes(formData['valueInfoShared']['value']) ? 'Required' : ''}},
      rateInstructorKnowledge: {...formData['rateInstructorKnowledge'], 'value': userData.rateInstructorKnowledge, dataSet: {...formData['rateInstructorKnowledge']['dataSet'], isError: !answersForUpdatedOptionsQuestion.includes(formData['rateInstructorKnowledge']['value']), errorMessage: !answersForUpdatedOptionsQuestion.includes(formData['valueInfoShared']['value']) ? 'Required' : ''}},
      understandingImprovement: {...formData['understandingImprovement'], 'value': userData.understandingImprovement, dataSet: {...formData['understandingImprovement']['dataSet'], isError: !answersForUpdatedOptionsQuestion.includes(formData['understandingImprovement']['value']), errorMessage: !answersForUpdatedOptionsQuestion.includes(formData['valueInfoShared']['value']) ? 'Required' : ''}},
      knowledgeApplication: {...formData['knowledgeApplication'], 'value': userData.knowledgeApplication, dataSet: {...formData['knowledgeApplication']['dataSet'], isError: !answersForUpdatedOptionsQuestion.includes(formData['knowledgeApplication']['value']), errorMessage: !answersForUpdatedOptionsQuestion.includes(formData['valueInfoShared']['value']) ? 'Required' : ''}},
      willRecommend: {...formData['willRecommend'], 'value': userData.willRecommend, dataSet: {...formData['willRecommend']['dataSet'], isError: false, errorMessage: ''}},
      missedTopicSugesstion: {...formData['missedTopicSugesstion'], 'value': userData.missedTopicSugesstion, dataSet: {...formData['missedTopicSugesstion']['dataSet'], isError: false, errorMessage: ''}},
      topicMostValuable: {...formData['topicMostValuable'], 'value': userData.topicMostValuable, dataSet: {...formData['topicMostValuable']['dataSet'], isError: false, errorMessage: ''}},
      suggestion: {...formData['suggestion'], 'value': userData.suggestion, dataSet: {...formData['suggestion']['dataSet'], isError: false, errorMessage: ''}},
    })
  }

  const getContent = (questionObject, singleOrMulti) => {
    // console.log("questionObject: ", questionObject.type);

    switch(questionObject.type) {

      case "simpleRadio":  return (
        <SimpleRadio item={questionObject['dataSet']} className={singleOrMulti} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
      )

      case "simpleCheckbox":  return (
        <Checkbox item={questionObject['dataSet']} className={singleOrMulti} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
      )
      
      case "simpleInput":  return (
        <Input item={questionObject} setCoursesList={setCoursesList} className={singleOrMulti} prefillApplicationForm={prefillApplicationForm} setUserExistsData={setUserExistsData} btnDisabled={btnDisabled} formType="postNysedra" setBtnDisabled={setBtnDisabled}  completeQuestionData={questionObject} formData={formData} validateData={validateData} setFormData={setFormData}/>
      )
      
      case "checkboxWithOther":  return (
        <SimpleCheckboxWithOther item={questionObject} className={singleOrMulti} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
      )

      case "radioWithOther":  return (
        <SimpleRadioWithOther item={questionObject} className={singleOrMulti} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
      )
    
      case "twosideRadio":  return (
        <TwosideRadio  item={questionObject} className={singleOrMulti} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
      )

      case "groupedradio":return(
        <GroupedRadio item={questionObject} className={singleOrMulti} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
      )

      case "singlelineRadio":return(
        <SingleLineRadio item={questionObject} className={singleOrMulti} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
      )

      case "dropdown":return(
        <Dropdown coursesList={coursesList} className={singleOrMulti} item={questionObject} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
      )

      case "dob":  return (
        <DOB item={questionObject} className={singleOrMulti}  btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
      ) 

      case "checkboxWithExpander":return(
        <CheckboxWithExpander className={singleOrMulti} coursesList={[]} item={questionObject} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
      )
    
    }

  }

  return (
    <div className='card'>
        <div className='formbg'>
        <span className='formheading'><Translate>TRAINING POST SURVEY INFORMATION</Translate></span><br /><br />
        <div style={{display: 'grid', gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr)", gridColumnGap: '20px'}}>
            { 
              Object.keys(formData).map((eachQuestion, i) => {
              if (i < 7) {
                  return (
                  <div>
                      {getContent(formData[eachQuestion], 'single-element')}
                  </div>
                )
              }
              })
            }
        </div>
        {
        Object.keys(formData).map((eachQuestion, i) => {
          if (i >= 7) {
              return (
              <div>
                {getContent(formData[eachQuestion])}
              </div>
            )
          }
          })
        }
            <div className='single-submit-button'>
              <FormButton text="Submit" handleSubmit={() => handleSubmit()} loading={loader}/>
            </div>
        </div>
        <Modal isOpen={show} onClosed={() => handleCancel()} className="modal-dialog-centered">
          <ModalHeader><Translate>{modalName}</Translate></ModalHeader>
          <ModalBody>
             <div>
                <div className='d-flex flex-column justify-evenly align-items-center'>
                  <img src={FormSubmitted} style={{height: '100px', width: '100px'}}></img>
                  <p className='text-center fs-3'><Translate>Thank You!! Your response has been submitted</Translate></p>
                </div>
              </div>
          </ModalBody>
      </Modal>
       <ToastContainer>
        
      </ToastContainer>
    </div>
  )
}

export default Posttraining
