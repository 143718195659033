import { isArray } from 'lodash-es';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Translate } from 'react-auto-translate';
// import './index.css'

const CheckboxWithExpander1 = (props) => {
    // console.log(props)

    const [selectedChildOptions, setSelectedChildOptions] = useState([])

    const [otherField, setOtherField] = useState(false)
    const [otherFieldData, setOtherFieldData] = useState('')
    const handleChange = (event, option, parentIndex) => {
        if (option.option === "Other") {
            console.log("option: ", event, option);
            setOtherField(event.target.checked)
            if (event.target.checked === false) {
                let valueArray = props.item['value']
                let updated = props.item['value']
                valueArray.forEach((answerItem, i) => {
                    if (answerItem['value'] === 'Other') {
                        // console.log("answerItem.substring(0, 6): ", answerItem, i, (answerItem.split(':'))[0] === 'Other', (answerItem.split(':'))[0]);
                        updated = updated.filter((item, index) => (item['value'] !== 'Other'))
                        console.log("updated: "+i, updated);
                    }
                })
                props.item['value'] = updated
                props.item.dataSet.isOtherSelect = false

                const allOptions = props?.item?.dataSet?.options || []
                for (let i = 0 ; i < allOptions.length; i++) {
                    if (allOptions[i]['option'] === option.option) {
                        allOptions[i]['showChildren'] = false
                    }
                }
            } else {
                if (props?.item?.dataSet?.isRequired && (props.item['otherValue']).length <= 0) {
                    props.item.dataSet.isError = true
                    props.item.dataSet.errorMessage = 'Required'
                } else {
                    props.item.dataSet.isError = false
                    props.item.dataSet.errorMessage = ''
                }
                props.item.dataSet.isOtherSelect = true

                const newArray = props.item['value']
                const filteredData = newArray.filter((selectedOption) => selectedOption['value'] !== "None")

                props.item['value'] = [...filteredData, {value: 'Other', subValue: ''}]

                // console.log("values: ", value, newArray);
                const allOptions = props?.item?.dataSet?.options || []
                for (let i = 0 ; i < allOptions.length; i++) {
                    if (allOptions[i]['option'] === option.option) {
                        allOptions[i]['showChildren'] = true
                    }
                }
            }
        } else {
            let value = props.item['value']
            let newArray = props.item['value']
            let isExists = false
            for (let i = 0; i < value.length; i++) {
                if (value[i]['value'] === event.target.value) {
                    isExists = true
                    break
                } 
            }

            if (isExists) {
                // Update selected child options ---------------------------------------------------------------------------
                let updatedSelectedChildOptions = selectedChildOptions
                updatedSelectedChildOptions = updatedSelectedChildOptions.filter((item) => item.parentIndex !== parentIndex)
                setSelectedChildOptions(updatedSelectedChildOptions)
                // ----------------------------------------------------------------------------------------------------------
                
                const filteredData = value.filter((selectedOption) => selectedOption.value !== option.option)
                props.item['value'] = filteredData

                if (option.option === "None") {
                    props.item.dataSet.isNoneSelect = false
                    setOtherField(false)
                    setOtherFieldData('')
                    props.item.otherValue = ''
                }

                console.log("values: ", value, newArray);
                const allOptions = props?.item?.dataSet?.options || []
                for (let i = 0 ; i < allOptions.length; i++) {
                    if (allOptions[i]['option'] === option.option) {
                        allOptions[i]['showChildren'] = false
                    }
                }
            } else {
                newArray.push({value: event.target.value, subValue: ''})
                if (option.option === "None") {
                    props.item['value'] = [{value: option.option, subValue: '', key: ''}]   
                    setOtherField(false)
                    setOtherFieldData('')
                    props.item.otherValue = ''
                    props.item.dataSet.isNoneSelect = true
                    props.item.dataSet.isOtherSelect = false
                    const allOptions = props?.item?.dataSet?.options || []
                    for (let i = 0 ; i < allOptions.length; i++) {
                        // if (allOptions[i]['option'] === option.option) {
                            allOptions[i]['showChildren'] = false
                        // }
                    }
                } else {
                    let updatedArray = newArray
                    for (let i = 0; i < newArray.length; i++) {
                        if (newArray[i]['value'] === "None") {
                            updatedArray = updatedArray.filter((item) => item['value'] !== 'None')
                            break;
                        }
                    }
                    props.item['value'] = updatedArray
                    props.item.dataSet.isNoneSelect = false
                }
                console.log("values: ", value, newArray);
                const allOptions = props?.item?.dataSet?.options || []
                for (let i = 0 ; i < allOptions.length; i++) {
                    if (allOptions[i]['option'] === option.option) {
                        allOptions[i]['showChildren'] = true
                    }
                }
            }
            // props.item.dataSet.options = allOptions
            // console.log("allOptions: " , option);
            // if (option.option !== "None") {
            //     props.item.dataSet.isError = true
            //     props.item.dataSet.errorMessage = 'Required'
            // } else {
            //     props.item.dataSet.isError = false
            //     props.item.dataSet.errorMessage = ''
            // }
        }
        props?.validateData()
    }

    const handleOtherField = (event) => {
        if ((props?.item['value']).length === 1 && (props.item['otherValue']).length === 0) {
            props.item.dataSet['isError'] = true
            props.item.dataSet['errorMessage'] = 'Required'
        } else {
            props.item.dataSet['isError'] = false
            props.item.dataSet['errorMessage'] = ''
        }
        props.item['otherValue'] = event.target.value
        let valueArray = props.item['value']
        let updated = props.item['value']
        valueArray.forEach((answerItem, i) => {
            if (answerItem.value === 'Other') {
                updated = updated.filter((item, index) => i !== index)
            }
        })
        updated.push(`Other: ${event.target.value}`)
        console.log("updated: ", updated);
        // props.item['value'] = updated
        props?.validateData()
    }
    
    const handleChangeChildren = (checked, optionObject, value, parentIndex) => {
        const allOptions = props?.item?.dataSet?.options || []
        console.log("handleChangeChildren: ", value, allOptions);
        if (checked) {
            const selectedChildOptionsArray = selectedChildOptions
            selectedChildOptionsArray.push({parentIndex: parentIndex, value: value})
            setSelectedChildOptions(selectedChildOptionsArray)
        } else {
            let selectedChildOptionsArray = selectedChildOptions
            selectedChildOptionsArray = selectedChildOptions.filter((item) => item.value !== value)
            setSelectedChildOptions(selectedChildOptionsArray)
        }
        for (let i = 0 ; i < allOptions.length; i++) {
            if (allOptions[i]['option'] === optionObject.option) {
                let subValues = allOptions[i]['subValue']
                if (checked) {
                    subValues.push(value)
                } else {
                    subValues = subValues.filter(item => item !== value)
                }
                console.log("updatedSubValues: ", subValues,  allOptions[i]['subValue']);
                allOptions[i]['subValue'] = subValues
                allOptions[i]['key'] = optionObject?.key
            }
        }
        console.log("optionObject.option: ", allOptions);
        props.item.dataSet.options = allOptions
        let valueArray = props?.item?.value
        // console.log("valueArray1: ", valueArray);
        for (let i = 0; i < valueArray.length; i++) {
            if (valueArray[i]['value'] === optionObject.option) {
                valueArray[i]['subValue'] = allOptions[i]['subValue']
                valueArray[i]['key'] = optionObject?.key
            }
        }
        props.item.value = valueArray
        props?.validateData()
    }

    const getOtherComponent = () => {
        let valueArray = props?.item?.value
        // console.log("valueArray1: ", valueArray);
        for (let i = 0; i < valueArray.length; i++) {
            if (valueArray[i]['value'] === 'Other') {
                return (
                    <input id={'other'}
                    name={props?.item?.groupName}
                    className='w-100 mb-2'
                    // value={option} 
                    defaultValue={otherFieldData}
                    onChange={(event) => handleOtherField(event)}/> 
                )
            }
        }
    }

    // console.log("otherFieldDataIndex: ", otherFieldDataIndex);

    const isChecked = (value, questionKey, optionKey) => {
        const valueArray = props?.item?.value
        console.log("value value: ", questionKey, optionKey, props?.completeQuestionData?.dataSet?.options);
        if (questionKey === 'knowledgeRatings') {
            console.log("valueArray: ", valueArray, value);
            for (let i = 0; i < valueArray.length; i++) {
                if (valueArray[i]['value'] === value) {
                    // if ((valueArray[i]['subValue']).length > 0) {
                        return true
                    // } else {
                    //     return false
                    // }
                    // if (valueArray[i]['value'] === "None" && value === "None") {
                    // } else {
                    //     // if (props?.item?.dataSet?.isNoneSelect === true) {
                    //     //     return false
                    //     // } else {
                    //     //         return true
                    //     //     }
                    //     }
                } else {
                    // return false
                }
            }
        } else {
            if (valueArray.length === 1) {
                if (valueArray[0]['value'] === value) {
                    if (valueArray[0]['value'] === "None" && value === "None") {
                        return true
                    } else {
                        if (props?.item?.dataSet?.isNoneSelect === true) {
                            return false
                        } else {
                            if (valueArray[0]['value'] === value) {
                                return true
                            } else {
                                return false
                            }
                        }
                    }
                }
            } else {
                console.log("valueArray: ", valueArray, value);
                for (let i = 0; i < valueArray.length; i++) {
                    if (valueArray[i]['value'] === value) {
                        if (valueArray[i]['value'] === "None" && value === "None") {
                            return true
                        } else {
                            if (props?.item?.dataSet?.isNoneSelect === true) {
                                return false
                            } else {
                                    return true
                                }
                            }
                    } else {
                        // return false
                    }
                }
            }
        }
    }

    const getQuestion = () => {
        if ((props?.item?.dataSet?.question).includes('electrical')) {
            const splittedQuestion = (props?.item?.dataSet?.question).split('electrical')
            return <p>{splittedQuestion[0]} <span style={{color: 'black'}}><u><Translate>electrical</Translate></u></span> <Translate>{splittedQuestion[1]}</Translate> {props?.item?.dataSet?.isRequired ? <span className='required'>*</span> : null}</p>
        } else if ((props?.item?.dataSet?.question).includes('thermal')) {
            const splittedQuestion = (props?.item?.dataSet?.question).split('thermal')
            return <p>{splittedQuestion[0]} <span style={{color: 'black'}}><u><Translate>thermal</Translate></u></span> <Translate>{splittedQuestion[1]}</Translate> {props?.item?.dataSet?.isRequired ? <span className='required'>*</span> : null}</p>    
        } else {
            return props?.item?.dataSet?.question
        }
    }

    const customValidation = (parentValueIndex, childOption) => {
        console.log("selectedChildOptions: ", selectedChildOptions)
        let isChecked = false
        for (let i = 0; i < selectedChildOptions.length; i++) {
            const item = selectedChildOptions[i]
            if (item.value === childOption) {
                isChecked = true
                console.log("item.parentIndex === `${parentValueIndex}`: ",typeof item.parentIndex, parseInt(parentValueIndex));
                if (item.parentIndex === parseInt(parentValueIndex)) { // Block 0 === Block 1
                    return false
                } else {
                    return true
                }
            }
        }

        return isChecked
    }

    return (
        <div className={`single-group-child ${props?.item?.dataSet['isError'] ? 'error-in-question' : ''}`}>
            <strong className='question-element'>{getQuestion()}</strong>
            {(props?.item?.dataSet?.options).map((option, i) => {
                return (
                    <div className='option-container d-flex' key={i}>
                        <div>
                            {/* {console.log("isChecked: ", isChecked(option.option, props?.item?.dataSet?.groupName, option.key))} */}
                            <div>
                                <input type="checkbox" 
                                // id={(option.option).toLowerCase()}
                                name={props?.item?.dataSet?.groupName}
                                defaultValue={option.option} 
                                disabled={props?.btnDisabled}
                                // checked={(props?.item?.value).includes(option || 'Other')}
                                checked={isChecked(option.option, props?.item?.dataSet?.groupName, option.key)}
                                onChange={(event) => {handleChange(event, option, i)}}
                                >
                                </input>
                                <span><Translate>{option.option}</Translate></span>
                            </div>
                            <div>
                                <div>
                                    {(option['showChildren']) && (option.children).map((childOption) => {  
                                        return (
                                            <div className='option-container mx-5' key={i + childOption}>
                                                <input type="checkbox"
                                                name={option.groupName}
                                                // defaultValue={props?.completeQuestionData?.isChildren ? `${option['line1']} ${option['line2']} ${option['line3']}` : option}
                                                onChange={(event) => {handleChangeChildren(event.target.checked, option, childOption, i)}}
                                                // checked={(option.subValue).contains(childOption)}
                                                // checked={(props?.completeQuestionData?.value).includes({value: option.option, subValue: childOption})}
                                                // disabled={props?.btnDisabled}
                                                disabled={customValidation(i, childOption)}
                                                >
                                                </input>
                                                <span><Translate>{childOption}</Translate></span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}

            {getOtherComponent()}
            {/* {otherField || (props?.item?.value).includes('Other') ? <input id={'other'}
            name={props?.item?.groupName}
            className='w-100 mb-2'
            // value={option} 
            defaultValue={otherFieldData}
            onChange={(event) => handleOtherField(event)}/> : null
            } */}

            {props?.item?.dataSet['isError'] ? <p className='error-message-container'><Translate>{props?.item?.dataSet['errorMessage']}</Translate></p> : null}
        </div>
    )
}

export default CheckboxWithExpander1

// We want one component for one question type. But in your case you make one one component for input, radio, checkbox and then using all these for making one question component