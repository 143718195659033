import React, { useState } from 'react'
import { Translate } from 'react-auto-translate';
// import './index.css'

const SimpleRadioWithOther = (props) => {
    // console.log(props)

    const [otherField, setOtherField] = useState(false)
    const handleChange = (event, option) => {
        console.log("option1: ", option, props.item.dataSet.errorMessage, props?.item?.dataSet?.isRequired, props.item.dataSet['isError']);
        if (option === "Other" || option === "I don’t know") {
            if (props?.item?.dataSet?.isRequired) {
                props.item.dataSet['isError'] = true
                props.item.dataSet['errorMessage'] = 'Required'
            }
            props.item['value'] = 'Other'
            setOtherField(!otherField)
            props?.validateData()
        } else {
            props.item['value'] = event.target.value
            setOtherField(false)
            props.item.dataSet['isError'] = false
            props.item.dataSet['errorMessage'] = ''
            props?.validateData()
        }
    }

    const handleOtherField = (event) => {
        props.item['value'] = `Other: ${event.target.value}`
        props?.validateData()
    }

    return (
        <div className={`single-group-child ${props?.formType} special-component ${props?.btnDisabled ? "disabled" : ''} ${props?.item?.dataSet['isError'] ? 'error-in-question' : ''}`}>
            <strong className={`question-element ${props?.formType} special-component`}><Translate>{props?.item?.dataSet?.question}</Translate>{props?.item?.dataSet?.isRequired ? <span className='required'>*</span> : null}</strong>
            {(props?.item?.dataSet?.options).map((option, i) => {
                return (
                    <div className='option-container' key={i}>
                        <input type="radio" id={(option)}
                         name={props?.item?.dataSet?.groupName}
                         defaultValue={option} 
                         disabled={props?.btnDisabled}
                         checked={props?.item?.value !== null ? (props?.item?.value).includes(option || 'Other') : false}
                         onChange={(event) => {handleChange(event, option)}}>

                         </input>
                        <span className={`${props?.formType} option-label`}><Translate>{option}</Translate></span>
                    </div>
                )
            })}

            {otherField ? <input id={'other'}
            name={props?.item?.groupName}
            className='w-100 mb-2'
            // value={option} 
            onChange={(event) => handleOtherField(event)}/> : null
            }

            {props?.item?.dataSet['isError'] ? <p className='error-message-container'><Translate>{props?.item?.dataSet['errorMessage']}</Translate></p> : null}
        </div>
    )
}

export default SimpleRadioWithOther

// We want one component for one question type. But in your case you make one one component for input, radio, checkbox and then using all these for making one question component