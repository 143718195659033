import React from 'react'
import {Routes,Route, useLocation, Router, Navigate } from 'react-router-dom';
import App from './App'
import Header from './Components/Header'
import Footer from './Components/Footer'
import Elevatorpitchform from './Forms/Elevatorpitchform';
import Pretraining from './Forms/Pretraining';
import Posttraining from './Forms/Posttraining';
import Occupationform from './Forms/Occupationform';
import Studentuploads from './Forms/Studentuploads';
import './App.css'
import StudentApplication from './Forms/studentApplication';
import ADNYApplication from './Forms/adnyForm';
import WeActStudentApplication from './Forms/WeActForms/studentApplications';
import WeActFooter from './Forms/WeActForms/WeActComponents/Footer';
import ReactGA from 'react-ga4';
import TestStudentApplication from './Forms/testStudentApplications';
import PageNotFound from './Components/ErrorPages/404';
import AdnyForms from './Forms/Adny';

import SoulFulFooter from './Forms/SoulFulSynergy/components/footer';
import WilldanRegistrationForm from './Forms/willdanRegistrationForm';
import WiildanFooter from './Forms/Willdan/WilldanFooter';
import File from './public';
// import NewSoulfulSynergyApplications from './Forms/newSoulfulSynergy';

const App2 = () => {
  const {pathname} = useLocation();

  if (document.location.href.includes("/cea")) {
    document.location.replace('/application')
  } else if (document.location.href.includes("/nyserda-pre-survey")) {
    document.location.replace('/training-pre-survey')
  } else if (document.location.href.includes("/nyserda-post-survey")) {
    document.location.replace('/training-post-survey')
  } 

  ReactGA.initialize("G-JHZP14LZGM");
  return (
    <div>
      <Header/>
      <div className={`body ${pathname === "/we-act-application" ? 'weact' : null}`}>
        <Routes>
          <Route path='/' element={<App />} />
          <Route path='/adny' element={<AdnyForms />} />
          <Route path={'/application'} element={<StudentApplication/>} />
          <Route path='/test-applications' element={<TestStudentApplication/>} />
          <Route path='/elevator' element={<Elevatorpitchform/>} />
          <Route path='/training-pre-survey' element={<Pretraining/>} />
          <Route path='/training-post-survey' element={<Posttraining/>} />
          <Route path='/adny-registration' element={<ADNYApplication/>} />
          <Route path='/we-act-application' element={<WeActStudentApplication/>} />
          <Route path='/adny-satisfaction-survey' element={<Occupationform/>} />
          <Route path='/public/files' element={<File/>} />
          {/* <Route path='/soulful-student-application' element={<SoulFulStudentApplication />} /> */}
          {/* <Route path='/soulful-student-application' element={<NewSoulfulSynergyApplications />} /> */}
          <Route path='/uploads' element={<Studentuploads/>} />
          <Route path='/willdan-registration-form' element={<WilldanRegistrationForm/>} />
          <Route path='*' element={<PageNotFound/>} />
        </Routes>
      </div>

        {pathname === "/we-act-application" ? <WeActFooter/> : pathname === "/soulful-student-application" ? <SoulFulFooter/>  : pathname === "/willdan-registration-form" ? <WiildanFooter/> : <Footer/>}      
    </div>
  )
}

export default App2