import React from 'react'
import { Translate } from 'react-auto-translate'
import '../Radio.css'
const GroupedRadio = (props) => {
    console.log(props)
    const handleChange = (event, selectedRadioOption) => {
        console.log("event.target.value: ", event.target.value, selectedRadioOption)
        let obj = props.completeQuestionData['value']
        obj = {...obj, [event.target.value]: selectedRadioOption}
        props.completeQuestionData['value'] = obj
        props?.validateData()
    }

  return (
    <div className={`single-group-child ${props?.item?.dataSet['isError'] ? 'error-in-question' : ''}`}>
        <strong className='strongclass'><Translate>{props?.item?.dataSet.question}</Translate>{props?.item?.dataSet.isRequired ? <span className='mandatory'>*</span> : null}</strong>
        <div className='radiobuttons1'>
            <div className='radioheadings'>
                <span className='radio-span'></span>
                {
                    Object.keys(props?.item?.dataSet['options']).map((option,i)=>{
                        return(
                            <span className='radio-span'><Translate>{props?.item?.dataSet['options'][option]}</Translate></span>
                        )
                    })
                }
            </div>
                
            {
                Object.keys(props?.item?.dataSet?.choices).map((ch, index)=>{
                    
                    return(
                        <div className='question-option'>
                            <span className='radio-span'><Translate>{ch}</Translate></span>
                            {
                                Object.keys(props?.item?.dataSet?.options).map((option, i)=>{
                                    {console.log("checked: ", Object.values(props?.item?.value)[i] === props?.item?.dataSet['options'][option])}
                                    return(
                                        <div className='radio-span' key={ch + option}>
                                            <input type="radio" 
                                            name={ch + props?.item?.dataSet['options'][index]}
                                            onChange={(event) => handleChange(event, props?.item?.dataSet['options'][option])}
                                            disabled={props?.btnDisabled}
                                            // checked={i === 2}
                                            value={Object.keys(props?.item?.value)[index]}
                                            checked={Object.values(props?.item?.value)[index] === props?.item?.dataSet['options'][option]}
                                            ></input>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                    
                })
                
            }
            
        </div>
        {props?.item?.dataSet['isError'] ? <p className='error-message-container mt-2'><Translate>{props?.item?.dataSet['errorMessage']}</Translate></p> : null}
   
    </div>
  )
}

export default GroupedRadio
