import React, { useEffect, useState } from 'react'
import Checkbox from '../Inputs/Checkbox'
import FormButton from '../Components/FormButton'
import '../Inputs.css'
import SimpleRadio from '../Components/Radio/SimpleRadio'
import { toast } from 'react-toastify';
import { GetCertificates, getCoursePreferences, getCoursesByPriority, getReferrals } from '../Apis/courses'
import Input from '../Inputs/Input'
import CreatableSelect from 'react-select/creatable'
import { StandaloneSearchBox } from "@react-google-maps/api";
import { checkUserEmail, checkWildanRegistrationEmailVerified, getApplicationsVersions, getStates, verifyMyEmail } from '../Apis/studentApplication'
import { Badge, Modal, ModalBody, ModalHeader } from "reactstrap";
import GoogleMapAddress from '../Components/Map/map'
import SimpleRadioWithOther from '../Components/Radio/SimpleRadioWithOther/simpleRadioWithOther'
import SimpleCheckboxWithOther from '../Components/Checkbox/SimpleChcekBoxWithOther/simpleCheckboxWithOther'
import FormSubmitted from './../assets/submitted.png'
import moment from 'moment/moment'
import OTPInput, { ResendOTP } from "otp-input-react";
import MapPin from 'feather-icons-react/build/IconComponents/MapPin' 
import {Colors} from '../utils/colors'
import DOB from '../Inputs/Dob'
import RadioHavingOptionChildren from '../Components/Radio/RadioHavingOptionChildrens'
import { Translate } from 'react-auto-translate'
import ReactGA from 'react-ga4'
import MapView from "@arcgis/core/views/MapView"
import Map from "@arcgis/core/Map"
import FeatureLayer from "@arcgis/core/layers/FeatureLayer"
import Search from "@arcgis/core/widgets/Search"
import getIp from 'react-public-ip';
import { UAParser } from 'ua-parser-js'
import { loadModules } from "esri-loader"
import WilldanRegistrationFormParagraph from '../Components/Paragraphs/WilldanRegistrationFormParagraph'
import { willdanRegistrationForm } from '../Apis/willdanRegistrationForm'
import { EMAIL_REGEX } from '../utils/constants'
// import { hotjar } from 'react-hotjar';


const WilldanRegistrationForm = () => {
  // ** Form Data

  const [certificationsList, setCertificationsList] = useState([])
  const [referralList, setReferralList] = useState([])
  const [coursePreferences, setCoursePreferences] = useState([]);
  const [interestedCoursePreferences, setInterestCoursePreferences] = useState([]);
  const [states, setStates] = useState([]);
  const [show, setShow] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [Lat, setLat] = useState('')
  const [Lng, setLng] = useState('')
  const [version, setVersion] = useState('');
  const [modalName, setModalName] = useState('')
  const [cancelClicked, setCancelClicked] = useState(false)
  const [userExistsData , setUserExistsData] = useState({})
  const [willdanCoursePreferences, setWilldanCoursePreferences] = useState([])

  const [loader, setLoader] = useState(false)

  // ** Check is Disadvantage Communities
  const [IsDisadvantage, setIsDisadvantage] = useState('no')

  // ** Verify Email
  const [resendEmailBtnClicked, setResendEmailBtnClicked] = useState(false)
  const [OTP, setOTP] = useState("");
  const [userEmailVerified, setUserEmailVerified] = useState(false)

   // Make Options For Interested Course
   const interestedCoursePreferencesOptions = []
   interestedCoursePreferences.forEach(option => {
     interestedCoursePreferencesOptions.push(option['line1'])
   })
 
  //  console.log('interestedCoursePreferencesOptions: ', interestedCoursePreferencesOptions)

   // Make Options For Who Referred You?
   const refereralOptions = []
   referralList.forEach(option => {
     refereralOptions.push(option['name'])
   })
   
  //  console.log("refereralOptions: ", refereralOptions);
   
   // Make Options For Course Interested?
   const willdaninterestedCoursesOptions = []
   willdanCoursePreferences.forEach(option => {
    willdaninterestedCoursesOptions.push(option)
   })

  // city, date, versionName
  const [formData, setFormData] = useState(
    {
         workEmail: {value: '', dataSet: {question: "Work Email", isRequired: true, formDataKey: 'email', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', isEmail: true, userVerified: false, notifyMe: false, emailCommunication: false, disable: false}, 
         
         firstName: {value: '', dataSet: {question: "First Name", isRequired: true, formDataKey: 'firstName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true}, 

         lastName: {value: '', dataSet: {question: "Last Name", isRequired: true, formDataKey: 'lastName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true},

         personalEmail: {value: '', dataSet: {question: "Personal Email", isRequired: false, formDataKey: 'personalEmail', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text'}, 

         phoneNumber: {value: '', dataSet: {question: "Cell Phone", isRequired: true, formDataKey: 'phoneNumber', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'number', disable: true, notifyMe: false, smsCommunication: false},

         //  address: {value: '', dataSet: {question: "Address", isRequired: true, formDataKey: 'address', isError: false, errorMessage: ''}, type: 'simpleInputWithMap', inputType: 'text', disable: true}, 
        
         city: {value: '', dataSet: {question: "City", isRequired: true, formDataKey: 'city', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true}, 

         //  city: {value: '', dataSet: {question: "City", isRequired: false, formDataKey: 'city', isError: false, errorMessage: ''}, type: 'dropdown', inputType: 'text', disable: true},
         
         state: {value: '', dataSet: {question: "State", isRequired: true, formDataKey: 'state', isError: false, errorMessage: ''}, type: 'dropdown', inputType: 'text', disable: true},

        //  zipcode: {value: '', dataSet: {question: "Zip Code", isRequired: true, formDataKey: 'zipcode', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'number', disable: true},

         coursePreference: {value: '', dataSet: {question: "Please indicate which course you are most interested in attending. (Select the one that most applies to you)", options: willdanCoursePreferences, isRequired: true, groupName: 'coursePreference', formDataKey: 'coursePreference', isError: false, errorMessage: ''}, type: 'simpleRadio', isChildren: false, disable: true}, 
 
         interestedCourse: {value: [], dataSet: {question: "Please indicate your interest in future courses you may be interested in taking. This will help us developing new courses. (Select the one(s) that most applies to you)", options: ["Building Energy Auditing", "Building Envelope - Principles & ECMs", "Building Operations and Maintenance", "City Building Codes - Commercial", "Clean Heat - Principles & ECMs", "Construction Management", "Electric Vehicle Charging Station Installation", "Heating & DHW - Principles & ECMs", "HVAC Systems - Principles &  ECMs", "Lighting Systems - CALT Cert", "OSHA", "Project Management", "None of the above"], isRequired: true, groupName: 'interestedCourse', formDataKey: 'interestedCourse', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true}, 

         jobTitle: {value: '', dataSet: {question: "Job Title", isRequired: true, groupName: 'jobTitle', formDataKey: 'jobTitle', isError: false, errorMessage: ''}, type: 'simpleInput', disable: true},

         businessUnit: {value: [], dataSet: {question: "Business Unit", options: ["E3", "Genesys", "Grubb & Associates (DGA)", "Integral Analytics (IA)", "Weidt Group", "WES East (WLE/WENY/Lime Northeast & Southeast)", "WES West", "Willdan Engineering (WE)", "Willdan Financial Services", "Willdan Group, Inc. (WGI)", "Willdan Industrial", "Willdan Performance Engineering (WPE)", "Other"], isRequired: true, groupName: 'businessUnit', formDataKey: 'businessUnit', isError: false, errorMessage: ''}, type: 'radioWithOther', disable: true}, 
         
         regionProgram : {value: [], dataSet: {question: "Region or Program", options: ["MidAtlantic - FE JCPL (SBDI)", "MidAtlantic - FE MD (SBDI)", "MidAtlantic - FE PA ( SBDI)", "NE - Central Hudson (SBDI)", "NE - Eversource (SBDI)", "NE - National Grid ( SBDI)", "NW - Puget Sound", "NYC - ConEdison (MFEEP)", "NYC - ConEdison (SBDI)", "SE - Duke (SBDI)", "SE - Santee Cooper (SBDI)", "W - Glendale", "W - LADWP", "W - PGE", "W - SCE", "W - SDGE", "Other"], isRequired: true, groupName: 'regionProgram', formDataKey: 'regionProgram', isError: false, errorMessage: ''}, type: 'radioWithOther', disable: true},
         
         //  experienceEEC: {value: '', dataSet: {question: "How many years of experience do you have in the energy efficiency sector?", options: ["<5 years of experience in the clean energy industry", "<5 years of experience outside the clean energy industry", ">5 years of experience in the clean energy industry", ">5 years of experience outside the clean energy industry"], isRequired: true, groupName: 'experienceEEC', formDataKey: 'experienceEEC', isError: false, errorMessage: ''}, type: 'simpleRadio', isChildren: false, disable: true},
         managerName: {value: '', dataSet: {question: "Manager Name", isRequired: true, formDataKey: 'managerName', isError: false, errorMessage: ''}, type: 'simpleInput', disable: true}, 

         managerEmail: {value: '', dataSet: {question: "Manager Email", isRequired: true, formDataKey: 'managerEmail', isError: false, errorMessage: ''}, type: 'simpleInput', disable: true}, 

         //  jobInterested: {value: [], dataSet: {question: "Please select the job roles that interest you. Check all that apply.", options: ["Sales and Outreach", "Auditor and Junior Engineer", "Design and Engineering", "Site Inspector", "Installation and Contracting", "Inspection and M&V (Measurement & Verification)", "Operations & Maintenance", "Clean Energy Instructor"], isRequired: true, groupName: 'jobInterested', formDataKey: 'jobInterested', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true},
         
         managerTitle: {value: '', dataSet: {question: "Manager Title", isRequired: true, groupName: 'managerTitle', formDataKey: 'managerTitle', isError: false, errorMessage: ''}, type: 'simpleInput', disable: true}, 

         courseHope: {value: '', dataSet: {question: "Which of the following statements best describes what you hope to accomplish from this training? (Select the one that most applies to you)", options: ["Acquire new skills", "Improve skills for promotion", "Change Department or focus of employment", "My Manager sent me", "I just joined the company and want to learn", "I am not sure"], isRequired: true, groupName: 'courseHope', formDataKey: 'courseHope', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
         
         courseExpectation: {value: '', city: '', dataSet: {question: "Which response best describes your expectations for this course, given your experience and career goals? (Select the one that most applies to you)", options: ["The course will teach me new things I want to learn", "The course will provide a refresher on things I have learned before", "The course may be over my head", "The course may be below my level", "I am not sure"], isRequired: true, groupName: 'courseExpectation', formDataKey: 'courseExpectation', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
         
         suggestions: {value: '', dataSet: {question: "Please provide any additional suggestions you may have. Thanks for helping us fine-tune our courses so they are directed toward teaching you the most important subjects.", isRequired: false, groupName: 'suggestions', formDataKey: 'suggestions', isError: false, errorMessage: ''}, type: 'simpleInput', disable: true}, 

         emailVerified: false
    }
  )
 
  // User System Info
  const [userSystemInfo, setUserSystemInfo] = useState({info: "", ip: ""})

  useEffect(() => {
    document.title = "Willdan Staff Registration Form"
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });

    getStates().then(res => {
        if(res != null){
            const data = res.data
            const statesArray = []
            for(let i = 0; i < data.length; i++){
              let state = data[i].attributes.stateName;
              statesArray.push({label: state, value: state, isFixed: true})
            }
            setStates(statesArray);
        }else{
            
        }
    }).catch(error => {
      console.log("error: ", error);
    })

    getCoursesByPriority().then(res => {
        if (res !== null) {
          const responseData = res.data
          let coursePreferencesPriority = {}
          let interestedCoursePreferences = {}
          let referralPriority = {}
          let willdanCoursePreferences = {}
          for (let i = 0; i < responseData.length; i++) {
            if (responseData[i].attributes.type === 1) {
              coursePreferencesPriority = responseData[i].attributes.priorities
            } else if (responseData[i].attributes.type === 2) {
              interestedCoursePreferences = responseData[i].attributes.priorities
            } else if (responseData[i].attributes.type === 3) {
                referralPriority = responseData[i].attributes.priorities
            } else if (responseData[i].attributes.type === 4) {
              willdanCoursePreferences = responseData[i].attributes.priorities
            }
          }

          // Get courses list
          getCoursePreferences().then(res => {
            if (res !== null) {
              const coursePreferencesTable = []
              const interestedCoursePreferencesTable = []
              const willdanCoursePreferencesTable = []
              const data = res.data
              for (let i = 0; i < data.length; i++) {
                if (data[i].attributes.type === 1) {
                  coursePreferencesTable.push({
                    id: data[i].id,
                    line1: data[i].attributes.line1,
                    line2: data[i].attributes.line2,
                    line3: data[i].attributes.line3
                })
                } else if (data[i].attributes.type === 3) {
                  willdanCoursePreferencesTable.push(data[i].attributes.line1)
                } else {
                  interestedCoursePreferencesTable.push({
                    id: data[i].id,
                    line1: data[i].attributes.line1
                  })
                }
              }

              // set items in array according to priority

              let coursePreferencesPriorityTableData = []
              Object.keys(coursePreferencesPriority).forEach(key => {
                const priority = coursePreferencesPriority[key] // 29
                ////  console.log("key: ", priority);
                for (let i = 0; i < coursePreferencesTable.length; i++) {
                  if (coursePreferencesTable[i].id === priority) {
                    coursePreferencesPriorityTableData.push(coursePreferencesTable[i])
                  }
                }
              })

              let makeNewArray1 = coursePreferencesTable
              let notPresentPriorityArray1 = makeNewArray1.filter(item => {
                  if (coursePreferencesPriorityTableData.includes(item) === false) {
                    return item
                  }
              })

              for (let i = 0 ; i < notPresentPriorityArray1.length; i++) {
                coursePreferencesPriorityTableData.push(notPresentPriorityArray1[i])
              }

              //////////////////////////////// For Interested Course Preferences ////////////////////////////////

              const interestedCoursePreferencesPriorityTableData = []
              Object.keys(interestedCoursePreferences).forEach(key => {
                const priority = interestedCoursePreferences[key] // 29
                for (let i = 0; i < interestedCoursePreferencesTable.length; i++) {
                  if (interestedCoursePreferencesTable[i].id === priority) {
                    interestedCoursePreferencesPriorityTableData.push(interestedCoursePreferencesTable[i])
                  }
                }
              })

              let makeNewArray = interestedCoursePreferencesTable
              let notPresentPriorityArray = makeNewArray.filter(item => {
                  if (interestedCoursePreferencesPriorityTableData.includes(item) === false) {
                    return item
                  }
              })

              for (let i = 0 ; i < notPresentPriorityArray.length; i++) {
                interestedCoursePreferencesPriorityTableData.push(notPresentPriorityArray[i])
              }


              //////////////////// Willdan Course Preferences ///////////////////
              const willdanCoursePreferencesPriorityTableData = []
              Object.keys(willdanCoursePreferences).forEach(key => {
                const priority = willdanCoursePreferences[key] // 29
                ////  console.log("key: ", priority);
                for (let i = 0; i < willdanCoursePreferencesTable.length; i++) {
                  if (willdanCoursePreferencesTable === priority) {
                    willdanCoursePreferencesPriorityTableData.push(willdanCoursePreferencesTable[i])
                  }
                }
              })

              const makeNewArray6 = willdanCoursePreferencesTable
              const notPresentPriorityArray6 = makeNewArray6.filter(item => {
                if (willdanCoursePreferencesPriorityTableData.includes(item) === false) {
                  return item
                }
              })

              console.log("data:-------------------", willdanCoursePreferencesTable);

              for (let i = 0; i < notPresentPriorityArray6.length; i++) {
                willdanCoursePreferencesPriorityTableData.push(notPresentPriorityArray6[i])
              }

            //  //  console.log("coursePreferencesPriorityTableData: ", interestedCoursePreferencesPriorityTableData);
              setCoursePreferences(coursePreferencesPriorityTableData)
              setInterestCoursePreferences(interestedCoursePreferencesPriorityTableData)
              setWilldanCoursePreferences(willdanCoursePreferencesPriorityTableData)
            } else {
              setCoursePreferences([])
              setInterestCoursePreferences([])
            }
          }).catch(() => {
            ////  console.log("error: ", error)
            setCoursePreferences([])
            setInterestCoursePreferences([])
            toast.error(<Translate>Something went wrong</Translate>)
          })

        } else {
          toast.error(<Translate>Something went wrong</Translate>)
          setCoursePreferences([])
          setInterestCoursePreferences([])
        }
    }).catch(error => {
      console.log("error: ", error)
      toast.error(<Translate>Something went wrong</Translate>)
      setCoursePreferences([])
      setInterestCoursePreferences([])
    })

    GetCertificates().then(res => {
      if (res !== null) {
        const data = res.data
        // Referral
        const certificationsTable = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].attributes.active) {
            certificationsTable.push(
              `${data[i].attributes.name}`
            )
          }
        }
        console.log("");
        setCertificationsList(certificationsTable)
      } else {
        setCertificationsList([])
        toast.error(<Translate>Something went wrong</Translate>)
      }
    }).catch(() => {
      setCertificationsList([])
      toast.error(<Translate>Something went wrong</Translate>)
    })

    getApplicationsVersions('studentapplication').then(res => {
      if(res !== null){
          const selectApplicationsArray = res.data[0]
          let vName = selectApplicationsArray.attributes.versionName
          setVersion(vName)
         //  console.log("selectApplicationsArray: ", vName)
      }else {

      }
    }).catch(error => {
        toast.error(<Translate>Something went wrong</Translate>, {position: 'top-right', autoClose: 3000})
      //  console.log("error: ", error);
    })

    async function getIPAddress() {
      const ipv4 = await getIp.v4() || "";
      const parser = new UAParser();
      // console.log("ipv4: ", ipv4);
      setUserSystemInfo({...userSystemInfo, info: parser.getResult(), ip: ipv4})
    }
    getIPAddress()
  }, [])

//   useEffect(() => {
//     if (interestedCoursePreferencesOptions.length > 0 && formData.additionalCourse.dataSet.options.length === 0)  {
//         setFormData({...formData, additionalCourse: {...formData['additionalCourse'], ['dataSet']: {...formData['additionalCourse']['dataSet'], 'options': interestedCoursePreferencesOptions}}})
//     }
//   }, [interestedCoursePreferencesOptions])

//   useEffect(() => {
//     if (refereralOptions.length > 0 && formData.sourceApplication.dataSet.options.length === 0)  {
//         setFormData({...formData, sourceApplication: {...formData['sourceApplication'], ['dataSet']: {...formData['sourceApplication']['dataSet'], 'options': refereralOptions}}})
//     }
//   }, [refereralOptions])

  useEffect(() => {
    if (willdaninterestedCoursesOptions.length > 0 && formData.coursePreference.dataSet.options.length === 0)  {
        setFormData({...formData, coursePreference: {...formData['coursePreference'], ['dataSet']: {...formData['coursePreference']['dataSet'], 'options': willdaninterestedCoursesOptions}}})
    }
  }, [willdaninterestedCoursesOptions])

//   useEffect(() => {
//     if (certificationsList.length > 0 && formData.certifications.dataSet.options.length === 0)  {
//         setFormData({...formData, certifications: {...formData['certifications'], ['dataSet']: {...formData['certifications']['dataSet'], 'options': certificationsList}}})
//       }
//   }, [certificationsList])
  
  // console.log("formData: ", formData);

  const [IsLoading, setIsLoading] = useState(false)
   
  const validateData = () => {
    let functionReturn = true
    let validateFormData = formData
    
    Object.keys(validateFormData).forEach(eachQuestion => {
      // Checking Required ------------------------------------------------------------------------------------------------------------------
      if (eachQuestion !== 'emailVerified' && eachQuestion !== "phoneVerified") {
        // console.log("email: ", eachQuestion, (validateFormData[eachQuestion]?.['value']).length);
        if (validateFormData[eachQuestion]?.['value'] !== null) {
          if (validateFormData[eachQuestion]?.['dataSet']?.['isRequired'] && ((validateFormData[eachQuestion]?.['value']).length <= 0 || validateFormData[eachQuestion].value === null)) {
            validateFormData[eachQuestion]['dataSet']['isError'] = true
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
            functionReturn = false
          } else {
            if (validateFormData[eachQuestion]['dataSet']) {
              validateFormData[eachQuestion]['dataSet']['isError'] = false
              validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
            }
          }
        }
        // Checking Required ------------------------------------------------------------------------------------------------------------------
      }

      if (eachQuestion === 'managerEmail') {
        if (validateFormData[eachQuestion]['value'].length) {
          if (EMAIL_REGEX.test(validateFormData[eachQuestion]['value'])) {
            validateFormData[eachQuestion]['dataSet']['isError'] = false
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
          } else {
            validateFormData[eachQuestion]['dataSet']['isError'] = true
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Invalid Email'
          }
        } else {
          validateFormData[eachQuestion]['dataSet']['isError'] = true
          validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
        }
      }
    })
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
    setFormData(validateFormData)
    return functionReturn
  }

  // Submit Form ----------------------------------------------------------------------------------
  let dateString = new Date();
  dateString = moment(dateString).format('YYYY-MM-DD');
  const handleSubmit = () => {
    console.log("validateData(): ", validateData(), formData);
    if (validateData()) {
    //   console.log("finalFormData: ", {
    //     "fName": formData['fName']['value'],
    //     "mName": formData['mName']['value'],
    //     "phoneNumber": formData['phoneNumber']['value'],
    //     "email": formData['email']['value'],
    //     "address": formData['address']['value'],
    //     "city": formData['city']['value'],
    //     "state": formData['state']['value'],
    //     "zipcode": formData['zipcode']['value'],
    //     "linkedin": formData['linkedin']['value'],
    //     "isEighteen": formData['isEighteen']['value'],
    //     "reasonEnroll": formData['reasonEnroll']['value'],
    //     "experienceEEC": formData['experienceEEC']['value'],
    //     "courceInterested": formData['courceInterested']['value'],
    //     "additionalCourse": (formData['additionalCourse']['value']).map(item => item).join(','),
    //     "jobInterested": (formData['jobInterested']['value']).map(item => item).join(','),
    //     "pastExperienceAreas": (formData['pastExperienceAreas']['value']).map(item => item).join(','),
    //     "highestEducation": formData['highestEducation']['value'],
    //     "certifications": formData['certifications']['value'],
    //     "NYSERDA_priorityPopulations": (formData['NYSERDA_priorityPopulations']['value']).map(item => item).join(','),
    //     "NYSERDA_disadvantagedCommunity": formData['NYSERDA_disadvantagedCommunity']['value'],
    //     "sex": formData['sex']['value'],
    //     "ethnicity": formData['ethnicity']['value'],
    //     "sourceApplication": formData['sourceApplication']['value'],
    //     "referredBy": formData['referredBy']['value'],
    //     "date": dateString,
    //     "resume": formData['resume']['value'],
    //     "versionName": version
    // });
    try {
      setLoader(true)
      let finalData = {
        firstName: formData['firstName']['value'],
        lastName: formData['lastName']['value'],
        workEmail: formData['workEmail']['value'],
        personalEmail: formData['personalEmail']['value'],
        phoneNumber: formData['phoneNumber']['value'],
        // address: formData['address']['value'],
        city: formData['city']['value'],
        state: formData['state']['value'],
        // zipcode: `${formData['zipcode']['value']}`,
        coursePreference: (formData['coursePreference']['value']).replace(/undefined/g, ''),
        interestedCourse: (formData['interestedCourse']).value.map(item => item).join(','),
        
        jobTitle: formData['jobTitle']['value'],
        businessUnit: formData['businessUnit']['value'],
        regionProgram: formData['regionProgram']['value'],
        managerName: formData['managerName']['value'],
        managerEmail: formData['managerEmail']['value'],
        managerTitle: formData['managerTitle']['value'],
        courseHope: formData['courseHope']['value'],
        courseExpectation: formData['courseExpectation']['value'],
        suggestions: formData['suggestions']['value'],

        formLanguage: localStorage.getItem('newFormFromLanguage') || 'en',
        isEmailVerified: formData.emailVerified,
        additionalData: userSystemInfo
      }

      // console.log(finalData);

      if (formData['personalEmail']['value'].length === 0) {
        delete finalData.personalEmail
      }
      willdanRegistrationForm({data: finalData}).then(res => {
        if (res !== null) {
          toast.success(<Translate>Application Submitted Successfully</Translate>)
          if (formData.emailVerified) {
            setShow(true)
            handleReloadForm()
          } else {
            setShow(true)
            setModalName("Verify Your Email")
          }
          setInterval(() => {
            if (userEmailVerified) {
              handleReloadForm()
            } else {
              checkWildanRegistrationEmailVerified(formData['workEmail']['value']).then(res => {
                // if (res !== null) {
                  if (res.status) {
                    setUserEmailVerified(true)
                    handleReloadForm()
                  } else {
                    setUserEmailVerified(false)
                  }
                // }
              })
            }
          }, 5000)
        } else {
          setLoader(false)
        }
      }).catch(error => {
        setLoader(false)
        console.log("error: ", error);
        toast.error(<Translate>Something went wrong</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
      })
    } catch (error) {
      console.log("error: ", error)
    }
    } else {
      toast.error(<Translate>Please Fill All The Required Fields</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
    }
  }

  // const handleVerifyMyEmail = () => {
  //   if (OTP !== null && OTP !== undefined && OTP.length === 4) {
  //     setresendEmailBtnClicked(true)
  //     verifyMyEmail({
  //       emailOrPhone: formData.email.value,
  //       OTP: OTP 
  //    }).then(res => {
  //       if (res.status) {
  //         setresendEmailBtnClicked(false)
  //         toast.success(<Translate>{res.message}</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
  //         setFormData({...formData, emailVerified: true})
  //         handleReloadForm()
  //       } else {
  //         setresendEmailBtnClicked(false)
  //         toast.error(<Translate>{res.message}</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
  //       }
  //     }).catch(error => {
  //       console.log("error: ", error);
  //       setresendEmailBtnClicked(false)
  //       toast.error(<Translate>Something went wrong</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
  //     })
  //   } else {
  //     toast.error(<Translate>Please enter the OTP</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
  //   }
  // }

  const handleReloadForm = () => {
    setCancelClicked(true)
    setModalName('Thank You')
    setTimeout(() => {
      window.location.reload()
    }, 3000)
  }

  const handleResendEmail = () => {
    checkUserEmail({email: formData?.email.value, type: 'student'}).then(res => {
      console.log("checkUserExists: ", res);
      if (res.status) {
        toast.success('Verification link re-send successfully', { position: 'top-right', autoClose: 2000, hideProgressBar: true })
        setResendEmailBtnClicked(true)
        handleReloadForm()
      } else {
        setResendEmailBtnClicked(false)
      }
    }).catch(error => {
      setResendEmailBtnClicked(false)
      console.log("error: ", error);
      toast.error('Something went wrong!! try again later', { position: 'top-right', autoClose: 2000, hideProgressBar: true })
    })
  }
  // Submit Form ----------------------------------------------------------------------------------

  // Dropdown Componenet
  const handleSelectState = (item) => {
    console.log(item);
    setFormData({...formData, state: {...formData['state'], 'value': item.value}})
  }

  // Address Component
  const handleAdderssByMap = () => {
    setShow(true)
  }

  const handleCancel = () => {
      setShow(false)
  }

  const [referencess, setReference] = useState(null)
  const onLoad = ref => {
    let searchBox = null
    searchBox = ref
    setReference(ref)
  };

  // const search = async (googleAddress) => {
  //   console.log("googleAddress: ", googleAddress);

  //   const [Map, MapView, FeatureLayer, Search] = await loadModules([
  //     "esri/Map",
  //     "esri/views/MapView",
  //     "esri/layers/FeatureLayer",
  //     "esri/widgets/Search"
  //   ])

  //   try {
  //     const myMap = new Map({
  //     basemap: "streets-relief-vector"
  //     })

  //     console.log("map: ", myMap)
  
  //     const myView = new MapView({
  //         container: "viewDiv",
  //         map: myMap,
  //         center: [-76, 42.8], 
  //         zoom: 7
  //     })

  //     console.log("map 1: ", myView)
  
  //     myView.popup.dockOptions = {
  //     // Disable the dock button so users cannot undock the popup
  //     buttonEnabled: false
  //     }
  
  //     myView.ui._removeComponents(["attribution"])
  
  //     const Interim_DAC = new FeatureLayer({
  //     url: "https://services7.arcgis.com/ZR2wjW0JGwgm9bhz/arcgis/rest/services/FinalDisadvantagedCommunities/FeatureServer",
  //     popupTemplate: {
  //     // Enable a popup
  //     title: "Interim DAC" // Show attribute value
  //     // content: "Clicked Point" // Display text in pop-up
  //     }
  //     })

  //     myMap.add(Interim_DAC)           
  //     // console.log("map 2: ", Interim_DAC)
  
  //     const searchWidget = new Search({
  //     view: myView
  //     })

  //     myView.ui.add(searchWidget, {
  //         position: "top-left",
  //         index: 2
  //     })

  //     searchWidget.search(googleAddress).then(res => {
  //       // console.log("response: ", res?.results[0]['results'][0]['feature']['geometry']);
  //       const geometry = res?.results[0]['results'][0]['feature']['geometry']
  //       // console.log("geometry: ", res?.results[0]['results'][0]['feature']['geometry']);
  //       getAddress(geometry);
  //     }).catch(error => {
  //       // console.log("geometry error: ", error);
  //     })
      
  //     function resolveValue(value) {

  //       console.log("map 5: ", value);
  //         var geoId = null;
  //         var userMessage = "";
  //         console.log("value 12: ", value);
  //         if(value == null){
  //             userMessage = "";
  //             console.log("userMessage - " + userMessage);
  //             // alert('Address Not Meet')
  //             setIsDisadvantage('no')
  //             // document.getElementById('resultDiv').innerHTML = userMessage
  //         }
  //         else if(value == "no data found"){
  //             userMessage = " is outside a DAC";
  //             console.log("userMessage - " + userMessage);
  //             // alert('Address Not Meet')
  //             setIsDisadvantage('no')
  //             // document.getElementById('resultDiv').innerHTML = userMessage
  //         }
  //         else if(value.features.length > 0){
  //             geoId = value.features[0].attributes.GEOID;
  //             userMessage = "<br>"+ "This address meets the interim criteria identified for a disadvantaged community.";
  //             console.log("userMessage - " + userMessage);
  //             // alert('Address Meet')
  //             setIsDisadvantage('yes')
  //             // document.getElementById('resultDiv').innerHTML = userMessage
  //         }
  //         else{
  //             userMessage = "<br>"+ "This address does not meet the interim criteria identified for a disadvantaged community.";
  //             console.log("userMessage - " + userMessage);
  //             // alert('Address Not Meet')
  //             setIsDisadvantage('no')
  //             // document.getElementById('resultDiv').innerHTML = userMessage
  //         }
  //     }

  //     function getAddress(point) {
  //         const query = Interim_DAC.createQuery()
  //         query.geometry = point  
  //         query.spatialRelationship = "intersects"
  //         query.returnGeometry = false
  //         query.outFields = ["GEOID"]
  //         Interim_DAC.queryFeatures(query)
  //         .then(function(value) {
  //             resolveValue(value)
  //         }, function(err) {
  //             console.error("This is an error", err)
  //             // this.error()
  //         })
  //     }
  
  //     searchWidget.on("select-result", function(event) {
  //         // setIsAddressSearched(true)
  //         getAddress(event.result.feature.geometry)
  //     })
  //     searchWidget.on("search-clear", function() {
  //         // console.log("cleared")
  //         // setIsAddressSearched(false)
  //         // setIsDisadvantage(false)
  //     })
  //   } catch (error) {
  //     // alert('Address Not Meet')
  //     setIsDisadvantage('no')
  //   }
  // }

  const onPlacesChanged = () => {
    let selectedAddress = referencess.getPlaces()
    let latitude = selectedAddress[0].geometry.location.lat()
    let longitude = selectedAddress[0].geometry.location.lng()

    let addressComponentsArray = [];
    addressComponentsArray = selectedAddress[0].address_components;
    // let zipcode = ''
    let state = ''
    let city = ''
    for (let i = 0 ; i < addressComponentsArray.length ; i++) {
      let addObjtypes = addressComponentsArray[i].types
      if (addObjtypes.includes('postal_code')) {
          // zipcode = `${addressComponentsArray[i].long_name}`
          console.log("addObjtypes: ", addressComponentsArray[i].long_name);
          // break;
      }
      
      if (addObjtypes.includes('administrative_area_level_1')) {
          state = `${addressComponentsArray[i].long_name}`
          console.log("addObjtypes: ", addressComponentsArray[i].long_name);
          // break;
      }

      if (addObjtypes.includes('locality')) {
        city = addressComponentsArray[i].long_name
      }
    }
    
    console.log("event: ", selectedAddress[0].geometry.location.lat(), selectedAddress[0].geometry.location.lng())
    setLat(latitude); setLng(longitude);
    setFormData({...formData, address: {...formData['address'], 'value': selectedAddress[0].formatted_address}, state: {...formData['state'], 'value': state}, city: {...formData['city'], 'value': city}})
    // search(selectedAddress[0].formatted_address)
  };

  const setAddressByMap = (addressComponent) => {
    console.log("addressComponent: ", addressComponent);
    setFormData({...formData, address: {...formData['address'], 'value': addressComponent.formattedAddress}, state: {...formData['state'], 'value': addressComponent.state}, city: {...formData['city'], 'value': addressComponent.city}})
    setLat(addressComponent.latitude)
    setLng(addressComponent.longitude)
    // search(addressComponent.formattedAddress)
  }

  // Prefil Form Data
  const prefillApplicationForm = (data) => {
    const userData = data.attributes
    console.log("prefillApplicationForm: ", userData);


    // Check Data For RadioHavingChildrens Component
    let questionOptions = []
    if (userData.currentEmploymentStatus) {
      let value = (userData.currentEmploymentStatus).split(',').map(element => element.trim()) || []

      // if (formData.currentEmploymentStatus.dataSet.options)
      questionOptions = formData.currentEmploymentStatus.dataSet.options
      for (let i = 0 ; i < questionOptions.length; i++) {
        for (let j = 0 ; j < value.length; j++) {
          if ((questionOptions[i].childrens).includes(value[j])) {
            questionOptions[i]['showChildren'] = true
          }
        }
      }
    } else {
      questionOptions = formData.currentEmploymentStatus.dataSet.options
    }

    console.log("questionOptions: ", questionOptions);

    setFormData({...formData,
      fName: {...formData['fName'], 'value': userData.fName, dataSet: {...formData['fName']['dataSet'], isError: false, errorMessage: ''}, emailCommunication: userData.emailCommunication},
      mName: {...formData['mName'], 'value': userData.mName, dataSet: {...formData['mName']['dataSet'], isError: false, errorMessage: ''}},
      phoneNumber: {...formData['phoneNumber'], 'value': userData.phoneNumber, dataSet: {...formData['phoneNumber']['dataSet'], isError: false, errorMessage: ''}, smsCommunication: userData.smsCommunication},
      address: {...formData['address'], 'value': userData.address, dataSet: {...formData['address']['dataSet'], isError: false, errorMessage: ''}},
      state: {...formData['state'], 'value': userData.state, dataSet: {...formData['state']['dataSet'], isError: false, errorMessage: ''}},
      city: {...formData['city'], 'value': userData.city, dataSet: {...formData['city']['dataSet'], isError: false, errorMessage: ''}},
      // zipcode: {...formData['zipcode'], 'value': userData.zipcode, dataSet: {...formData['zipcode']['dataSet'], isError: false, errorMessage: ''}},
      linkedin: {...formData['linkedin'], 'value': userData.linkedin, dataSet: {...formData['linkedin']['dataSet'], isError: false, errorMessage: ''}},
      isEighteen: {...formData['isEighteen'], 'value': userData.isEighteen, dataSet: {...formData['isEighteen']['dataSet'], isError: false, errorMessage: ''}},
      // reasonEnroll: {...formData['reasonEnroll'], 'value': userData.reasonEnroll, dataSet: {...formData['reasonEnroll']['dataSet'], isError: false, errorMessage: ''}},
      experienceInsideCleanEnergy: {...formData['experienceInsideCleanEnergy'], 'value': userData.experienceInsideCleanEnergy !== null ? userData.experienceInsideCleanEnergy : '', dataSet: {...formData['experienceInsideCleanEnergy']['dataSet'], isError: false, errorMessage: ''}},
      experienceOutsideCleanEnergy: {...formData['experienceOutsideCleanEnergy'], 'value': userData.experienceOutsideCleanEnergy !== null ? userData.experienceOutsideCleanEnergy : '', dataSet: {...formData['experienceOutsideCleanEnergy']['dataSet'], isError: false, errorMessage: ''}},
      courceInterested: {...formData['courceInterested'], 'value': userData.courceInterested, dataSet: {...formData['courceInterested']['dataSet'], isError: false, errorMessage: ''}},
      additionalCourse: {...formData['additionalCourse'], 'value': userData.additionalCourse ? (userData.additionalCourse).split(',').map(element => element.trim()) : [], dataSet: {...formData['additionalCourse']['dataSet'], isError: false, errorMessage: ''}},
      enrolledInCourses: {...formData['enrolledInCourses'], 'value': userData.enrolledInCourses ? (userData.enrolledInCourses).split(',').map(element => element.trim()) : [], dataSet: {...formData['enrolledInCourses']['dataSet'], isError: false, errorMessage: ''}},
      pastExperienceAreas: {...formData['pastExperienceAreas'], 'value': userData.pastExperienceAreas ? (userData.pastExperienceAreas).split(',').map(element => element.trim()) : [], dataSet: {...formData['pastExperienceAreas']['dataSet'], isError: false, errorMessage: ''}},
      highestEducation: {...formData['highestEducation'], 'value': userData.highestEducation, dataSet: {...formData['highestEducation']['dataSet'], isError: false, errorMessage: ''}},
      recentlyGraduated: {...formData['recentlyGraduated'], 'value': userData.recentlyGraduated !== null ? userData.recentlyGraduated : '', dataSet: {...formData['recentlyGraduated']['dataSet'], isError: false, errorMessage: ''}},
      currentEmploymentStatus: {...formData['currentEmploymentStatus'], 'value': userData.currentEmploymentStatus ? (userData.currentEmploymentStatus) : [], dataSet: {...formData['currentEmploymentStatus']['dataSet'], isError: false, errorMessage: '', options: questionOptions}},
      certifications: {...formData['certifications'], 'value': userData.certifications ? (userData.certifications).split(',').map(element => element.trim()) : null, dataSet: {...formData['certifications']['dataSet'], isError: false, errorMessage: ''}},
      seekingEmployment: {...formData['seekingEmployment'], 'value': userData.seekingEmployment ? userData.seekingEmployment : '', dataSet: {...formData['seekingEmployment']['dataSet'], isError: false, errorMessage: ''}},
      optedPlacementSupport: {...formData['optedPlacementSupport'], 'value': userData.optedPlacementSupport ? userData.optedPlacementSupport : '', dataSet: {...formData['optedPlacementSupport']['dataSet'], isError: false, errorMessage: ''}},
      NYSERDA_priorityPopulations: {...formData['NYSERDA_priorityPopulations'], 'value': (userData.NYSERDA_priorityPopulations).split(',').map(element => element.trim()), dataSet: {...formData['NYSERDA_priorityPopulations']['dataSet'], isError: false, errorMessage: ''}},
      // NYSERDA_disadvantagedCommunity: userData.NYSERDA_disadvantagedCommunity,
      sex: {...formData['sex'], 'value': userData.sex,'stateShortcode': userData.stateShortcode || '',  dataSet: {...formData['sex']['dataSet'], isError: false, errorMessage: ''}},
      pronoun: {...formData['pronoun'], 'value': userData.pronoun !== null && userData.pronoun !== undefined ? userData.pronoun : '', dataSet: {...formData['pronoun']['dataSet'], isError: false, errorMessage: ''}},
      ethnicity: {...formData['ethnicity'], 'value': userData.ethnicity, dataSet: {...formData['ethnicity']['dataSet'], isError: false, errorMessage: ''}},
      sourceApplication: {...formData['sourceApplication'], 'value': userData.sourceApplication, dataSet: {...formData['sourceApplication']['dataSet'], isError: false, errorMessage: ''}},
      referredBy: {...formData['referredBy'], 'value': userData.referredBy, dataSet: {...formData['referredBy']['dataSet'], isError: false, errorMessage: '', isRequired: userData.sourceApplication === "Referral" || userData.sourceApplication === "Other"}, isVisible: userData.sourceApplication === "Referral" || userData.sourceApplication === "Other"},
      date: dateString,

      resume: {...formData['resume'], 'value': userData.resume, dataSet: {...formData['resume']['dataSet'], isError: false, errorMessage: ''}},
      coverLetterUpload: {...formData['coverLetterUpload'], 'value': userData.coverLetterUpload ? userData.coverLetterUpload : '', dataSet: {...formData['coverLetterUpload']['dataSet'], isError: false, errorMessage: ''}},
      versionName: "new_2023",
      formLanguage: localStorage.getItem('newFormFromLanguage') || 'en',

      emailCommunication: userData.emailCommunication,
      smsCommunication: userData.smsCommunication,
      emailVerified: userData.emailVerified,
      phoneVerified: userData.phoneVerified,

      userData: userSystemInfo
      // experienceEEC: {...formData['experienceEEC'], 'value': userData.experienceEEC, dataSet: {...formData['experienceEEC']['dataSet'], isError: false, errorMessage: ''}},
      // jobInterested: {...formData['jobInterested'], 'value': (userData.jobInterested).split(','), dataSet: {...formData['jobInterested']['dataSet'], isError: false, errorMessage: ''}},
      // genderIdentity: {...formData['genderIdentity'], 'value': userData.genderIdentity !== null && userData.genderIdentity !== undefined ? userData.genderIdentity : '', dataSet: {...formData['genderIdentity']['dataSet'], isError: false, errorMessage: ''}},
    })

    setIsDisadvantage(userData?.NYSERDA_disadvantagedCommunity || 'no')
  }

  const getContent = (questionObject, singleOrMulti) => {
    if (questionObject !== null) {
      switch(questionObject.type) {
  
        case "simpleRadio":  return (
          <SimpleRadio className={singleOrMulti} item={questionObject['dataSet']} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData} formData={formData}/>
        )
  
        case "simpleCheckbox":  return(
          <Checkbox className={singleOrMulti} item={questionObject['dataSet']} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        )
        
        case "simpleInput":  return(
          <Input className={singleOrMulti} setUserExistsData={setUserExistsData} item={questionObject} btnDisabled={btnDisabled} formType="willdan-registration-form" setBtnDisabled={setBtnDisabled} prefillApplicationForm={prefillApplicationForm} completeQuestionData={questionObject} validateData={validateData} formData={formData} setFormData={setFormData}/>
        )
        
        case "dropdown":  return (
          <div className={`single-group-child ${singleOrMulti === "single-element" ? singleOrMulti : ''} ${questionObject.dataSet['isError'] ? 'error-in-question' : ''}`}>
            <strong className='strongclass'><Translate>{questionObject.dataSet.question}</Translate><span className='mandatory'>*</span></strong>
            <div className='ui form'>
              <div className='field'>
                  <CreatableSelect options={states} isDisabled={btnDisabled} placeholder="Select State" className='react-select' styles={{width: '100%'}} value={{label: formData.state.value, value: formData.state.value, isFixed: true}} classNamePrefix='select' onChange={(item) => handleSelectState(item)}/>
              </div>
          </div> 
          {questionObject['dataSet']['isError'] ? <p className='error-message-container'><Translate>{questionObject['dataSet']['errorMessage']}</Translate></p> : null}
          </div>
        )
  
        case "simpleInputWithMap": return (
          <div className={`single-group-child ${singleOrMulti === "single-element" ? singleOrMulti : ''} ${questionObject.dataSet['isError'] ? 'error-in-question' : ''}`}>
          <strong className='strongclass'><Translate>{questionObject.dataSet.question}</Translate><span className='mandatory'>*</span>
          {!btnDisabled ? 
          <Badge role={'button'} className="float-end" color='warning' style={{color: 'black'}} onClick={() => handleAdderssByMap()}><MapPin className="primary" size={18} stroke="black"/><Translate>Locate on Map</Translate></Badge>
            // <span className="fs-4" role={'button'} onClick={() => handleAdderssByMap()}>{`(Click to Locate on Map)`}</span>
            : null
          }
          </strong> 
          <div className='ui form'>
            <div className='field'>
            <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
                <input type="text" value={formData.address.value} disabled={btnDisabled} className="form-control" name="address" id="address" placeholder="Enter Address" onChange={(event) => setFormData({...formData, ['address']: {...formData['address'], 'value': event.target.value}})}/>
            </StandaloneSearchBox>
            </div>
        </div> 
        {questionObject['dataSet']['isError'] ? <p className='error-message-container'><Translate>{questionObject['dataSet']['errorMessage']}</Translate></p> : null}
        </div>
        )
  
        case "radioWithOther":  return (
            <SimpleRadioWithOther className={singleOrMulti} item={questionObject} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
            // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
        )
  
        case "checkboxWithOther":  return (
            <SimpleCheckboxWithOther className={singleOrMulti} item={questionObject} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
            // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
        )
        
        // case "upload":  return (
        //     <Upload className={singleOrMulti} file={formData.resume.value} setLoader={setLoader} item={questionObject} email={formData.email.value} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        //     // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
        // )
       
        // case "dob":  return (
        //     <DOB className={singleOrMulti} file={formData.resume.value} item={questionObject} email={formData.email.value} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        //     // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
        // ) 
       
        // case "radioWithExpander":  return (
        //   <RadioHavingOptionChildren className={singleOrMulti} item={questionObject['dataSet']} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        //     // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
        // )
      }
    }
  }

  return (
    <div className='card'>
      <div className='formbg'>
      <script src='./analytics.js'></script>
      <div id='viewDiv' style={{height: '10px', opacity: 0}}>

      </div>
        {/* <Helmet>
          <script> (function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3369438,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv='); </script>
        </Helmet> */}
      <WilldanRegistrationFormParagraph/>
        <div className='single-elements-container'>
            {Object.keys(formData).map((eachQuestion, i) => {
            if (i <= 5) {
                return (
                <div>
                    {getContent(formData[eachQuestion], 'single-element')}
                </div>
              )
            }
            })}
            {/* {formData.additionalCourse.dataSet.options.length ?  */}
            {/* //    : null
            // } */}
        </div>
        
        {Object.keys(formData).map((eachQuestion, i) => {
          if (i > 5) {
              return (
              <div>
                {getContent(formData[eachQuestion])}
              </div>
            )
          }
        })}
        {/* {formData.additionalCourse.dataSet.options.length ? 
           : null
        } */}
        <div className='single-submit-button'>
          <FormButton text="Submit" handleSubmit={() => handleSubmit()} loading={loader}/>
        </div>
      </div>

      
      <Modal isOpen={show} onClosed={() => handleCancel()} className="modal-dialog-centered">
          <ModalHeader>
            <Translate>
              {modalName !== "Thank You" && modalName !== "Verify Your Email" ? 'Mark Address' : modalName}
            </Translate>
            </ModalHeader>
          <ModalBody>
            {modalName === "Thank You" ? <div>
                <div className='d-flex flex-column justify-evenly align-items-center'>
                  <img src={FormSubmitted} style={{height: '100px', width: '100px'}}></img>
                  <p className='text-center fs-3'><Translate>Thank You!! Your response has been submitted</Translate>
                  {/* {formData.emailVerified && cancelClicked === false ? <Translate>{' and your email is verified successfully'}</Translate> : ''} */}
                  </p>
                  {/* {!formData.emailVerified ? 
                    <>
                      <strong className='text-center lh-1'>It seems that your email is not verified, Please enter the OTP that was sent to your email address.
                        </strong>

                        <OTPInput className="my-2" value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} />
                        <div className='d-flex justify-content-end w-100 mt-4'>
                          <Button style={{backgroundColor: `${Colors.primary} !important`}} disabled={resendEmailBtnClicked} onClick={() => handleVerifyMyEmail()}>Verify Email</Button>
                          <Button className='mx-2' onClick={() => handleReloadForm()}>Cancel</Button>
                        </div>
                    </> : null
                  } */}
                </div>
            </div> : modalName === "Verify Your Email" ? <div>
                <div className='d-flex flex-column justify-evenly align-items-center'>
                  {/* <p className='text-center fs-3'>Thank You!! Your response has been submitted</p> */}
                  {!formData.emailVerified ? 
                    <>
                      {/* <strong className='text-center lh-1 my-2'><Translate>We have sent an email with an OTP and the verification link you can verify your email by either of the process.</Translate>
                        </strong> */}

                        {/* <OTPInput className="my-2" value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} />
                        
                        <p className='text-center my-2'><Translate>OR</Translate></p> */}
                        <p className='text-justify fs-5'><Translate>Check your email for a verification link from Willdan Clean Energy Academy CMS: Email Verification. Make sure to click on the link received to complete your submission.</Translate></p>
                        
                        <div className='d-flex justify-content-end w-100 mt-4'>
                          <button style={{backgroundColor: `${Colors.primary} !important`}} className="button-primary" disabled={resendEmailBtnClicked} onClick={() => handleResendEmail()}><Translate>Re-send Email</Translate></button>
                          <button className='mx-2 button-secondary' onClick={() => handleReloadForm()}><Translate>Cancel</Translate></button>
                        </div>
                    </> : null
                  }
                </div>
            </div> : <GoogleMapAddress handleCancel={handleCancel} setAddressByMap={setAddressByMap}></GoogleMapAddress>
            }
          </ModalBody>
      </Modal>
    </div>
  )
}

export default WilldanRegistrationForm