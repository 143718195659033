import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { GetResume } from '../Apis/public'
import { Document, Page, pdfjs } from 'react-pdf'
import { Spinner } from 'reactstrap'
import { toast } from 'react-toastify'

const File = () => {
    
    const params = useLocation()
    console.log("params:-----------", params)
    const fileUrl = (params.search).length ? (params.search).replace('?', '') : ''
    
    const [numPages, setNumPages] = useState(null)
    const [loading, setLoading] = useState(true)
    const [pdfLink, setPdfLink] = useState(null)
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`
  
    const onDocumentLoadSuccess = ({ numPages }) => {
      console.log("numPages: ", numPages);
      setNumPages(numPages)
    }

    useEffect(() => {
        const pathname = params.pathname
        if (pathname.includes('file')) {
            GetResume(fileUrl).then(res => {
                console.log("res:----------------", res, pdfLink)
                if (res?.status !== null) {
                    setPdfLink(res.url)
                } else {
                    setPdfLink(null)
                }
                setLoading(false)
            }).catch(error => {
                setLoading(false)
                setPdfLink(null)
                console.log(error)
            })
        } else {
            setLoading(false)
        }
    }, [])

    if (loading) {
        return (
            <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', minHeight: '70vh'}}>
                <Spinner/>
            </div>
        )
    }

    if (pdfLink) {
        return (
            <div style={{display: 'flex'}}>
                <div style={{width: '100%'}}>
                    <Document loading={<span style={{color: 'white', fontSize: 25, fontWeight: 'bold', width: '100%'}}>Loading PDF......</span>} file={{url: pdfLink}} onLoadSuccess={onDocumentLoadSuccess} onError={() => toast.error('Something went wrong!!')}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} className='d-flex justify-content-evenly w-100' pageNumber={index + 1} renderTextLayer={false} renderAnnotationLayer={false}/>
                        ))}
                    </Document>
                </div>
            </div>
        )
    }

    return (
        <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', minHeight: '70vh'}}>
            <p style={{color: 'white', fontSize: 45, fontWeight: 'bold'}}>Something went wrong!!</p>
        </div>
    )

}

export default File