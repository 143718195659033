import React from 'react'
import { Translate } from 'react-auto-translate'
import '../../../src/Inputs.css'

const WilldanRegistrationFormParagraph = () => {
  return (
    <div>
        <h3 className='centertext'>
          <Translate>
          Willdan Staff Professional Development Courses
          </Translate>
        </h3>
      <p className='para'>
        <Translate>
          Willdan Clean Energy Academy (WCEA) is a training and education program that aims to provide individuals with the knowledge and skills needed to work in the clean energy and energy efficiency field. It offers a variety of courses and certifications, such as building energy modeling, energy auditing, and energy management. The WCEA provides a pathway to enter the clean energy industry and help existing professionals advance their careers. The WCEA is a flexible program which can be tailored to meet the specific needs of different organizations, such as utilities, municipalities, and businesses.
        </Translate>
      </p>
      <span className='formheading'>
        <Translate>
          Registrant Information
        </Translate>
      </span><br /><br />
    </div>
  )
}

export default WilldanRegistrationFormParagraph
