import React from 'react'
import '../../Footer.css'
import Willimg from '../../willimg.png'
const WiildanFooter = () => {
  return (
    <div className='footer'>
      <img src={Willimg} alt="" />
      <div className='footercontent'>
        <p></p>
        <h5>For More information, Contact:</h5>
        <br />
        <br />
        <b>Denise Sonnenberg</b><br />
        <i><u>dsonnenberg@willdan.com</u></i>
        <p className='copyright'>Copyright © 2023 Willdan Clean Energy Academy - All Rights are Reserved</p>
      </div>
    </div>
  )
}
export default WiildanFooter